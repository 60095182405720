































import {Vue, Component} from 'vue-property-decorator';
import { markerBorder, markerCenter, pattern } from './shapesRepo';
import ColorPicker from '@/components/colorPicker.vue';
import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';

@Component({ components: { ColorPicker } })
export default class Shape extends Vue {
    get patterns() {
        return pattern;
    }

    get markerBorders() {
        return markerBorder
    }

    get markerCenters() {
        return markerCenter
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get pattern() {
        return this.projectData.projectData.customizations.pattern;
    }

    setPattern(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    pattern: value
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get markerIn() {
        return this.projectData.projectData.customizations.marker_in;
    }

    get markerBorderColor() {
        return this.projectData.projectData.customizations.marker_out_color;
    }

    set markerBorderColor(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    marker_out_color: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get markerCenterColor() {
        return this.projectData.projectData.customizations.marker_in_color;
    }

    set markerCenterColor(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    marker_in_color: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get markerColor() {
        const color =  this.projectData.projectData.customizations.markers_color;
        return color && color == 'on';
    }

    set markerColor(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    markers_color: value ? 'on' : null
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    setMarkerIn(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    marker_in: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get markerOut() {
        return this.projectData.projectData.customizations.marker_out;
    }

    setMarkerOut(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    marker_out: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    getPattern(pattern: string) {
        return require(`../../../../../assets/svg/qr-patterns/${pattern}.svg`)
    }

    getMarkerBorder(pattern: string) {
        return require(`../../../../../assets/svg/qr-marker-borders/${pattern}.svg`)
    }

    getMarkerCenters(pattern: string) {
        return require(`../../../../../assets/svg/qr-marker-centers/${pattern}.svg`)
    }
 }
