











import {Vue, Component} from 'vue-property-decorator';
import AudioQrSettings from './settings.vue';
import Preview from './preview.vue';

@Component({ components: { AudioQrSettings, Preview } })
export default class AudioQr extends Vue {
    done () {
        this.$emit('done');
    }
}
