




















import { formatDateTime } from '@/utils/dayjs';
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class FeedbackCard extends Vue {
    @Prop({ default: () => ({}) }) feedback!: any;

    get createdAt() {
        return formatDateTime(this.feedback.createdAt)
    }
}
