



































































import { getProjectData, updateProjectData } from '@/store/editorState';
import { generateId } from '@/utils/utils';
import { Vue, Component } from 'vue-property-decorator';
import ColorPicker from '@/components/colorPicker.vue';
import ImageUploader from '@/components/imageUploader.vue';

@Component({ components: { ColorPicker, ImageUploader } })
export default class Settings extends Vue {
    proceed() {
        this.$emit('done')
    }

    get mimeTypes() {
        return [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.presentation',
            'application/vnd.oasis.opendocument.text',
            'text/plain',
            'text/csv'
        ].join()
    }

    get title(): string {
        return this.imageContent.title;
    }

    set title(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.imageContent,
                    title: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleFont(): string {
        return this.imageContent.titleFont
    }

     set titleFont(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.imageContent,
                    titleFont: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleColor(): string {
        return this.imageContent.titleColor;
    }

    set titleColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.imageContent,
                    titleColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleSize(): string {
        return this.imageContent.titleSize;
    }

    set titleSize(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.imageContent,
                    titleSize: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get uploaderKey() {
        return generateId();
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get imageContent() {
        return this.projectData.projectData.content
    }

    get pageColor(): string {
        return this.imageContent.pageColor;
    }

    set pageColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.imageContent,
                    pageColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get documents() {
        return this.imageContent.documents;
    }

    handleDone(done: any) {
        const audioFiles = [
            ...this.documents,
            done
        ]

        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.imageContent,
                    documents: audioFiles
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    removeDocument(file: any) {
        const audioIndex = this.documents.findIndex((audio: any) => {
            return audio.id == file.id
        });

        if (audioIndex > -1) {
            const filesBuffer = this.documents;
            filesBuffer.splice(audioIndex, 1);

            const payload = {
                ...this.projectData,
                projectData: {
                    ...this.projectData.projectData,
                    content: {
                        ...this.imageContent,
                        audioFiles: filesBuffer
                    }
                }
            }


            updateProjectData(this.$store, payload);
        }
    }

}
