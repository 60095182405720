
















import {Vue, Component} from 'vue-property-decorator';
import LocationPicker from 'location-picker';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';

@Component({ components: { VueGoogleAutocomplete } })
export default class Google extends Vue {
    locationPicker: any | null = null;

    get projectData(){
        return getProjectData(this.$store);
    }

    setLink(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    link: value,
                    websiteUrl: value
                }
            }
        }


        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    mounted() {
        this.locationPicker = new LocationPicker('map', { setCurrentPosition: false, lat: 40.7322802, lng: -74.1232294 }, { zoom: 15 });

        (window as any).google.maps.event.addListener(this.locationPicker.map, 'idle',  (event: any) => {
            let location = this.locationPicker.getMarkerPosition();
            this.updatePicker(location.lat, location.lng)
        });

        (window as any).google.maps.event.addListener(this.locationPicker.map, 'click',  (event: any) => {
            let location = this.locationPicker.getMarkerPosition();
            this.updatePicker(location.lat, location.lng)
        });
    }

    updateMap(lat: number, long: number){
         var initialLocation = new (window as any).google.maps.LatLng(lat, long);
            this.locationPicker.map.setCenter(initialLocation);
            this.locationPicker.map.setZoom(13);

        const link = `https://www.google.com/maps/place/${lat},+${long}/@${lat},${long},15z`
        this.setLink(link);
    }

    updatePicker(lat: number, long: number) {
        const geocoder = new (window as any).google.maps.Geocoder();
        const latLng = new (window as any).google.maps.LatLng(lat, long);

        geocoder.geocode({ latLng }, (results: any, status: any) => {
            const address = results[0].formatted_address;
            (this.$refs.picker as any).update(address);
        });

        const link = `https://www.google.com/maps/place/${lat},+${long}/@${lat},${long},15z`
        this.setLink(link);
    }

    setCurrentLocation() {
        navigator.geolocation.getCurrentPosition((position) => { this.updateMap(position.coords.latitude, position.coords.longitude) })
    }

    placeChanged(e: any) {
        this.updateMap(e.latitude, e.longitude);
    }

     proceed() {
        if(this.projectData.projectData.content.link.length == 0){
            this.$toast.error('pick a place');
            return;
        }

        this.$emit('done')
    }
}
