









































import { getActiveUser, setUser } from '@/store/authState';
import { initServer, SERVER } from '@/utils/server';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Settings extends Vue {
    userName = '';
    email = '';

    oldPassword = '';
    newPassword = '';
    get user() {
        return getActiveUser(this.$store);
    }

    mounted() {
        this.userName = this.user.firstName + ' ' + (this.user.lastName ? this.user.lastName: '');
        this.email = this.user.email;
    }

    updateUser() {
        const payload = {
            firstName: this.userName,
            id: this.user.id
        }

        initServer(SERVER)
        SERVER.post('user/edit', payload).then(response => {
            if(response.data.success){
                setUser(this.$store, { ...this.user,  firstName: this.userName, lastName: ''});
                this.$toast.success('User updated');
            } else {
                this.$toast.error('Something went wrong')
            }
        }).catch(err => {
            this.$toast.error('Something went wrong')
        })
    }

    updatePassword(){
        const payload = {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword
        }

        initServer(SERVER)
        SERVER.post('user/change-password', payload).then(response => {
            if(response.data.success){
                this.$toast.success('Password updated');
            } else {
                this.$toast.error(response.data.message)
            }
        }).catch(err => {
            this.$toast.error('Something went wrong')
        })
    }
}
