


















































import { SERVER } from '@/utils/server';
import { Vue, Component, Prop } from 'vue-property-decorator';
import FeedbackCard from './feedbackCard.vue'
import { Modal } from 'bootstrap';

@Component({components: { FeedbackCard }  })
export default class FeedbackModal extends Vue {
    @Prop({ default: '' }) id!: string
    @Prop({ default: () => ({}) }) project!: any

    feedbacks: any[] = [];


    isFetchingFeedback = true;

    get totalFeedback() {
        if(this.feedbacks.length > 0) {
            let feedbackSum = 0;
            this.feedbacks.forEach(feedback => {
                feedbackSum += feedback.rating;
            })
            return (feedbackSum / this.feedbacks.length).toFixed(2);
        }

        return 0;
    }
    
    mounted () {
        const projectId = this.id.split('_')[0]
        this.isFetchingFeedback = true;
        SERVER.get(`feedback/${projectId}`).then(response => {
            this.feedbacks = response.data.data;
        }).finally(() => {
            this.isFetchingFeedback = false;
        })
    }

    cancel() {
        Modal.getInstance(document.getElementById(this.id)!)?.hide()
    }


}
