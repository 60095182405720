






import {Vue, Component, Prop, Watch} from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
    @Prop({ default: 0 }) progress!: number;

    slideWidth = 0;
    containerWidth = 0;

    get slideStyle() {
        return {
            width: `${this.slideWidth}px`
        }
    }

    @Watch('progress')
    onProgressChange(){
        this.calculateWidth();
    }

    mounted() {
        const container = this.$refs.container as HTMLElement;
        this.containerWidth = container.getBoundingClientRect().width;
        this.calculateWidth();
    }

    calculateWidth() {
        const fillableWidth = this.containerWidth - 6;
        this.slideWidth = (this.progress/ 100) * fillableWidth;
    }
}
