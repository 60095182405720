










































import {Vue, Component} from 'vue-property-decorator';
import ColorPicker from '@/components/colorPicker.vue';
import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';

@Component({ components: { ColorPicker } })
export default class Colors extends Vue {

    get projectData() {
        return getProjectData(this.$store);
    }

    get transparentBackground() {
        const transparent = this.projectData.projectData.customizations.transparent;

        return transparent && transparent == 'on';
    }

    set transparentBackground(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    transparent: value ? 'on' : null,
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get gradientForeground() {
        const gradient = this.projectData.projectData.customizations.gradient;

        return gradient && gradient == 'on';
    }

    set gradientForeground(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    gradient: value ? 'on' : null
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get backgroundColor() {
        return this.projectData.projectData.customizations.backcolor;
    }

    set backgroundColor(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    backcolor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get foregroundColor() {
        return this.projectData.projectData.customizations.frontcolor;
    }

    set foregroundColor(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    frontcolor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get gradientColor() {
        return this.projectData.projectData.customizations.gradient_color;
    }

    set gradientColor(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    gradient_color: value
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get gradientStyle() {
        const gradientStyle = this.projectData.projectData.customizations.radial;
        return ( gradientStyle && gradientStyle == 'on' ) ? 'radial' : 'linear';
    }

    set gradientStyle(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    radial: value == 'radial' ? 'on' : null
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }
}
