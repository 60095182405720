


























import { Modal } from "bootstrap";
import { Vue, Component } from "vue-property-decorator";
import { createFolder } from '@/store/folderState';

@Component
export default class CreateFolder extends Vue {
    folderName = '';

    createFolder() {
        if(this.folderName.length == 0){
            this.$toast.error('Enter folder name');
            return;
        }

        const payload = {
            name: this.folderName
        }

        createFolder(this.$store, payload).then((response: any) => {
            this.$toast.success(response)
            Modal.getInstance(document.getElementById('createFolderModal')!)!.hide();
        }).catch((err: any) => {
            this.$toast.error(err)
        });
    }
}
