import { dynamicPageConfig } from "@/views/editor/projectData";

export type eventConfig = dynamicPageConfig & {
    title: string,
    bannerImage: string,
    description: string,
    startTime: string,
    endTime: string,
    buttonLink: string,
    buttonText: string,
    eventType: string[],
    venue: string,
    eventLink: string,
    password: string,
    linkButtonCta: string,
    titleColor: string,
    titleFont: string,
    titleSize: string
}

export const eventDefaults: eventConfig = {
    title: '',
    bannerImage: '',
    description: '',
    startTime: '',
    endTime: '',
    buttonLink: '',
    buttonText: '',
    eventType: ['physical'],
    venue: '',
    eventLink: '',
    password: '',
    pageColor: '#027bff',
    linkButtonCta: 'Join Now',
    titleColor: '#fff',
    titleFont: '',
    titleSize: '24',
}