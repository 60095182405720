






















































import { getWhitelabel, updateWhitelabelUser } from '@/store/whitelableState';
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import { Modal } from 'bootstrap';
import { userRoles } from '@/utils/userRoles';

@Component
export default class EditUserModal extends Vue {
  @Prop({ default: () => ({}) }) user!: any;

  firstName = '';
  lastName = '';
  email = '';
//   password = '';
  userRole = '';

  get userRoles () {
    return userRoles;
  }

  get whitelabel() {
    return getWhitelabel(this.$store);
  }

  @Watch('user')
  onUserChange(newValue: any) {
   this.setUser(newValue);
  }

  setUser(user: any){
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    // this.password = '';
    this.userRole = user.userRoles;
  }

  create() {
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        id: this.user.id,
        userRoles: this.userRole
      }

      updateWhitelabelUser(this.$store, payload).then(res => {
          this.cancel();
      })
  }

  validate() {
    if(this.firstName.length == 0){
      this.$toast.error('Enter first name');
      return false;
    }

    if(this.email.length == 0){
      this.$toast.error('Enter email');
      return false;
    }

    // if(this.password.length == 0){
    //   this.$toast.error('Enter password');
    //   return false;
    // }

    // if(this.password.length < 8) {
    //   this.$toast.error('Password length must be atleast 8 characters')
    //   return false;
    // }

    return true;
  }

   cancel() {
        Modal.getInstance(document.getElementById('editUserModal')!)!.hide();
    }

}
