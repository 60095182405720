











import {Vue, Component} from 'vue-property-decorator';
import { editorSteps } from './stepConfig';
import Stepper from './components/stepper.vue';
import ChooseCategory from './components/choose-category/choose.vue';
import { fetchFolders } from '@/store/folderState';
import EnterContent from './components/enter-information/enter.vue';
import Customize from './components/customize/customize.vue';
import DownloadQrCode from './components/download/download.vue';
import { getStepIndex, updateStepIndex } from '@/store/editorState';

@Component({ components: { Stepper, ChooseCategory, EnterContent, Customize, DownloadQrCode } })
export default class Editor extends Vue {
    get activeStepIndex() {
        return getStepIndex(this.$store)
    }

    set activeStepIndex(value) {
        updateStepIndex(this.$store, value);
    }

    mounted(){
        fetchFolders(this.$store)
    }

    get steps(){
        return editorSteps;
    }

    get currentStep() {
        return this.steps![this.activeStepIndex];
    }

    get currentStepComponent() {
        return this.currentStep.componentName;
    }

    handleDone() {
        this.activeStepIndex += 1;
    }

    stepClick(index: number) {
        // this.activeStepIndex = index;
        // console.log(index);
    }
}
