
















import {Vue, Component} from 'vue-property-decorator';
import { recoverpassword } from '@/store/authState';

@Component
export default class PasswordRecovery extends Vue {
    email = '';

    login() {
        this.$router.push({ name: 'Login' })
    }

    getPassword() {
        if(this.validate()){
            let payload: any = { email: this.email }

        recoverpassword(this.$store, payload).then((res) => {
            this.$toast.success('Password recovery email sent');
            this.$router.push({name: 'Login'})
            console.log('sent');
        }).catch(err=>{
            this.$toast.error('Unable to complete Operation');
        })
        }
    }

    validate() {
        if(this.email.length == 0){
            this.$toast.error('Enter your email');
            return false;
        }

        return true;
    }
}
