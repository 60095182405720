
























import { getParsedCountryCode } from '@/views/editor/components/enter-information/utils';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CountryCodeSelector extends Vue {
    @Prop({ default: '' }) value!: any;

    setCountryCode(value: string) {
        this.$emit('input', value);
    }

    searchTerm = '';

    get codes() {
        const parsedCodes = getParsedCountryCode();
        if(this.searchTerm.length > 0) {
            return parsedCodes.filter((code: any) => {
                return code.name.toLowerCase().includes(this.searchTerm.toLowerCase());
            })
        }

        return parsedCodes;
    }
}
