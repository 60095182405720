import { ApplicationState } from "./applicationState";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { initServer, SERVER } from "@/utils/server";
import { getWhitelabel } from './whitelableState';
type CustomizerContext = ActionContext<CustomizerState, ApplicationState>

export interface CustomizerState {
    isWhitelabel: boolean,
    customDetails: {
        siteName: string;
        siteColor: string;
        logoUrl: string;
        id: string
    } | null,
    showWebinarModal: boolean,
}

const initialState: CustomizerState = {
    isWhitelabel: false,
    customDetails: null,
    showWebinarModal: false
}

export const customizerState = {	
    namespaced: true, 
    state: initialState,
    getters: {
        getIsWhitelabel(state: CustomizerState) {
            return state.isWhitelabel;
        },

        getCustomDetails(state: CustomizerState) {
            return state.customDetails;
        },

        getShowWebinarModa(state: CustomizerState) {
            return state.showWebinarModal
        }
    },
    mutations: {
        setCustomDetails(state: CustomizerState, customDetails: any) {
            state.customDetails = customDetails;
        },

        setIsWhitelabel(state: CustomizerState, value: boolean) {
            state.isWhitelabel = value;
        },

        setShowWebinarModal(state: CustomizerState, value: boolean) {
            state.showWebinarModal = value;
        }
    },
    actions: {
        setIsWhitelabel(context: CustomizerContext, value: boolean) {
            context.commit('setIsWhitelabel', value);
        },

        fetchWhitelabelDetails(context: CustomizerContext, host: string) {
            return new Promise((resolve, reject) => {
                const request = SERVER.get(`wl/byHost/${host}`)
                
                request.then((response) => {
                    const apiResponse = response.data;
                    context.commit('setCustomDetails', apiResponse.data);
                    const siteColor = apiResponse.data.siteColor;
                    const root = document.documentElement;
                    root.style.setProperty('--color-primary', siteColor);

                    const favicon = document.querySelector('link[rel="icon"]')! as HTMLLinkElement;
                    favicon.href = apiResponse.data.logoUrl;

                    document.title = apiResponse.data.siteName;
                    resolve('done')
                }).catch((err) => {
                    reject(err)
                })
            });
        },

        setShowWebinarModal(context: CustomizerContext, value: boolean) {
            context.commit('setShowWebinarModal', value);
        }
    },
}

const { read, dispatch } = getStoreAccessors<CustomizerState, ApplicationState>('customizer');

const getters = customizerState.getters;
const actions = customizerState.actions;

export const fetchWhitelabelDetails = dispatch(actions.fetchWhitelabelDetails);
export const setIsWhitelabel = dispatch(actions.setIsWhitelabel);
export const setShowWebinarModal = dispatch(actions.setShowWebinarModal);

export const getIsWhitelabel = read(getters.getIsWhitelabel);
export const getCustomDetails = read(getters.getCustomDetails);
export const getShowWebinarModal = read(getters.getShowWebinarModa);