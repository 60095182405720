








import { setCurrentTool, getCurrentTool } from '@/store/customizeState';
import {Vue, Component} from 'vue-property-decorator';

@Component
export default class Sidebar extends Vue {
    sidebarItems = [
        {
            name: 'Colors',
            icon: 'colors.svg'
        },
        {
            name: 'Shape',
            icon: 'shape.svg'
        },
        {
            name: 'Logo',
            icon: 'logo.svg'
        },
        {
            name: 'Frames',
            icon: 'frames.svg'
        },
        {
            name: 'Settings',
            icon: 'settings.svg'
        }
    ];

    isActive(item: any){
        const currentTool = getCurrentTool(this.$store);
        return currentTool == item.name.toLowerCase();
    }

    src(icon: string){
        return require(`../../../../assets/svg/${icon}`)
    }

    setTool(item: any) {
        setCurrentTool(this.$store, item.name.toLowerCase());
    }
}
