

































































































import { Vue, Component } from "vue-property-decorator";
import ImageUploader from "@/components/imageUploader.vue";
import ColorPicker from "@/components/colorPicker.vue";
import { fetchWhitelabel, getWhitelabel, updateWhitelabel, saveWhitelabel, fetchWhitelabelUsers } from '@/store/whitelableState';
import { generateId } from '@/utils/utils';

@Component({ components: { ImageUploader, ColorPicker } })
export default class Whitelabel extends Vue {

    get uploaderKey() {
      return generateId();
    }

    get whitelabel() {
      return getWhitelabel(this.$store);
    }

    get hostName() {
      return this.whitelabel.hostName;
    }

    set hostName(value: string) {
      const payload = {
        ...this.whitelabel,
        hostName: value
      };

      updateWhitelabel(this.$store, payload);
    }

    get logoUrl() {
      return this.whitelabel.logoUrl;
    }

    set logoUrl(value: string) {
      const payload = {
        ...this.whitelabel,
        logoUrl: value
      };

      updateWhitelabel(this.$store, payload);
    }

    get siteName() {
      return this.whitelabel.siteName;
    }

    set siteName(value: string) {
      const payload = {
        ...this.whitelabel,
        siteName: value
      };

      updateWhitelabel(this.$store, payload);
    }

    get siteColor() {
      return this.whitelabel.siteColor;
    }

    set siteColor(value: string) {
      const payload = {
        ...this.whitelabel,
        siteColor: value
      };

      updateWhitelabel(this.$store, payload);
    }

    mounted() {
      if(this.whitelabel.hostName.length == 0){
        this.$toast.info('Fetching whitelabel details...')
      }
      fetchWhitelabel(this.$store).then(() => {
        fetchWhitelabelUsers(this.$store, this.whitelabel.id);
      })
    }

    saveWhitelabel() {
      //TODO: run validation
      if(this.validate()) {
        this.$toast.info('Saving whitelabel...')
        saveWhitelabel(this.$store).then(() => this.$toast.success('Whitelabel saved')).catch(() => this.$toast.error('Whitelabel not saved'))
      }
    }

    validate() {
      if(this.hostName.length == 0){
        this.$toast.error('Enter your domain');
        return false;
      }

      if(this.siteName.length == 0) {
        this.$toast.error('Enter your site name');
        return false;
      }

      if(this.siteColor.length == 0) {
        this.$toast.error('Enter your site color');
        return false;
      }

      if(this.logoUrl.length == 0) {
        this.$toast.error('Upload your logo');
        return false;
      }

      return true;
    }

    openReseller() {
      if(this.hostName.length == 0) {
        this.$toast.error('Set up your whitelabel first.')
      } else {
        this.$router.push({ name: 'WhitelabelUsers' })
      }
    }
}
