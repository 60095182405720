import { ApplicationState } from "./applicationState";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { projectData } from "@/views/editor/projectData";
import axios from 'axios';
import { generateId } from "@/utils/utils";
import { v4 as uuid } from 'uuid';
import { initServer, SERVER } from "@/utils/server";
import Vue from 'vue';

type EditorContext = ActionContext<EditorState, ApplicationState>

export interface EditorState {
    codePreview: string
    projectData: projectData,
    stepIndex: number,
    isEditing: boolean
}

const getInitialState = () => {
    return {
        stepIndex: 0,
        codePreview: '',
        projectData: {
            projectData: {
                content: {
                    type: 'static',
                    link: '',
                    section: '#static-link',
                    websiteUrl: ''
                },
                customizations: {
                    pattern: 'default',
                    marker_in: 'default',
                    marker_out: 'default',
                    backcolor: '#fff',
                    transparent: null,
                    gradient_color: '#000',
                    gradient: null,
                    radial: null,
                    size: 600,
                    level: 'M',
                    framecolor: '#000',
                    framelabel: 'SCAN ME',
                    label_font: 'AbrilFatface',
                    marker_in_color: '#000',
                    marker_out_color: '#000',
                    logo_size: '100',
                    label_text_size: '100',
                    frontcolor: '#000',
                    outer_frame: 'none',
                    option_logo: 'none',
                    custom_frame_color: '#000',
                    markers_color: null,
                    no_logo_bg: null
                }
            },
            title: 'Untitled Project',
            category: 'Website',
            folder: {
                name: 'Select a folder',
                id: ''
            },
            scanCode: generateId(),
            id: uuid(),
            qrCodeUrl: {
                png: '',
                svg: ''
            },
        },
        isEditing: false
    }
}


export const editorState = {	
     namespaced: true, 
     state: getInitialState(),
     getters: {
        getProjectData(state: EditorState) {
            return state.projectData
        },

        getCodePreviewString(state: EditorState) {
            return state.codePreview;
        },

        getStepIndex(state: EditorState) {
            return state.stepIndex;
        },

        getIsEditing(state: EditorState) {
            return state.isEditing;
        }
     },
     mutations: {
        updateProjectData(state: EditorState, projectData: projectData){
            state.projectData = projectData;
        },

        setCodePreview(state: EditorState, preview: string) {
            state.codePreview = preview;
        },

        setQrCodeUrl(state: EditorState, qrCodeUrl: any) {
            state.projectData.qrCodeUrl = qrCodeUrl;
        },

        initEdit(state: EditorState, project: any) {
            state.projectData = project;
        },

        updateStepIndex(state: EditorState, stepIndex: number) {
            state.stepIndex = stepIndex;
        },

        initEditor(state: EditorState) {
          Object.assign(state, getInitialState())
        },

        isEditing(state: EditorState, value: boolean) {
            state.isEditing = value;
        }
     },
     actions: {
        editQrCode(context: EditorContext, project: any){
            context.commit('initEdit', project);
            context.commit('isEditing', true);
        },

        initEditor(context: EditorContext){
            context.commit('initEditor'),
            context.commit('isEditing', false)
        },

        updateProjectData(context: EditorContext, projectData: projectData) {
            context.commit('updateProjectData', projectData);
            context.commit('setQrCodeUrl', { png: '', svg: '' })
        },

        updateStepIndex(context: EditorContext, stepIndex: number) {
            context.commit('updateStepIndex', stepIndex);
        },

        getQrCodePreview(context: EditorContext) {
            return new Promise<void>((resolve, reject) => {
                const payload = {
                    ...context.state.projectData.projectData.content,
                    ...context.state.projectData.projectData.customizations
                }

                const request = axios.post('https://engine.qrverse.io/ajax/process.php', jsonToFormData(payload), { headers: { 'Content-Type': 'multipart/form-data' } })
                
                // const request = axios.post('http://localhost:5000/ajax/process.php', jsonToFormData(payload), { headers: { 'Content-Type': 'multipart/form-data' } })
                
                request.then((response) => {
                    const apiResponse= response.data;
                    resolve(apiResponse)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        setCodePreview(context: EditorContext, preview: string) {
            context.commit('setCodePreview', preview);
        },

        createProject(context: EditorContext) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.post('projects', context.state.projectData);
                
                request.then((response) => {
                    const apiResponse = response.data;
                    if(apiResponse.success){
                        resolve(apiResponse.message)
                    } else {
                        reject(apiResponse.message);
                    }
                }).catch((err) => {
                    reject(err);
                })
            });
        },

        generateImages(context: EditorContext, payload: { svgString: string, projectId: string }) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.post('utils',  payload);

                request.then((response) => {
                    const apiResponse = response.data;
                    if(apiResponse.success){
                        context.commit('setQrCodeUrl', apiResponse.data.qrCodeUrl);
                        resolve(apiResponse.data)
                    } else {
                        reject('Something went wrong');
                    }
                }).catch((err) => {
                    reject(err);
                })
            });
        }
     },
}

const jsonToFormData = (json: any) => {
    const formData = new FormData();

    Object.keys(json).forEach(key => {
        if(json[key] != null){
            formData.append(key, json[key]);
        }
    })

    return formData;
}

const { read, dispatch } = getStoreAccessors<EditorState, ApplicationState>('editor');

const getters = editorState.getters;
const actions = editorState.actions;

export const getProjectData = read(getters.getProjectData);
export const getQrCodePreview = read(getters.getCodePreviewString);
export const getStepIndex = read(getters.getStepIndex);
export const isEditing = read(getters.getIsEditing);

export const updateProjectData = dispatch(actions.updateProjectData);
export const fetchQrCodePreview = dispatch(actions.getQrCodePreview);
export const setCodePreview = dispatch(actions.setCodePreview);
export const createProject = dispatch(actions.createProject);
export const generateImages = dispatch(actions.generateImages);
export const editQrCode = dispatch(actions.editQrCode);
export const updateStepIndex = dispatch(actions.updateStepIndex);
export const initEditor = dispatch(actions.initEditor);