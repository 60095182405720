












import { Vue, Component, Prop } from "vue-property-decorator";
import axios from "axios";
import { mediaUrl } from "@/utils/server";
import Loader from "@/components/loader.vue";
import { bytesToSize, generateId } from '@/utils/utils';

@Component({ components: { Loader } })
export default class ImageUploader extends Vue {
  @Prop() value!: string;
  @Prop({ default: '' }) uploaderKey!: string;
  @Prop({ default: 'Set Image' }) cta!: string;
  @Prop({ default: 'image/*' }) mimeTypes!: string;
  @Prop({ default: 3000000 }) size!: number
  isUploading = false;
  fileUpload(e: any) {
    if (this.isUploading == false) {
      const imageFile = e.target.files[0] as File;
      if (imageFile.size > this.size) {
        this.$toast.error(`Media should not be greater than ${bytesToSize(this.size)}`);
        return;
      }
      this.isUploading = true;
      const formData = new FormData();
      formData.append("file", imageFile);
      axios
        .post(`${mediaUrl}/media`, formData, { headers: { "Content-Type": "multipart/form-data" }, })
        .then(response => {
          const apiResponse = response.data;
          if(apiResponse.success){
            const imageUrl = apiResponse.data.file
            this.$emit('input', imageUrl);
            this.$emit('done', {
              id: generateId(),
              url: imageUrl,
              name: imageFile.name
            })
          } else {
            this.$toast.error('Try again. Something went wrong.');
          }
        })
        .catch(err => {
          this.$toast.error('Try again. Something went wrong.');
        })
        .finally(() => (this.isUploading = false));
    }
  }
}
