import axios, { AxiosInstance } from "axios";


// export const baseUrl = 'http://localhost:3213';
export const baseUrl = 'https://api.qrverse.io';

// export const appUrl = 'https://app.storiapp.io';
export const appUrl = 'https://app.qrverse.io'
// 
export const mediaUrl = 'https://media.qrverse.io';
// export const mediaUrl = 'http://localhost:3172';

export const dynamicScanUrl = 'https://scan.qrverse.io';

export const SERVER = axios.create({
    baseURL: baseUrl + "/",
});

SERVER.interceptors.response.use((response) => response, (err) => {
    if(err.response.status == 401){
        localStorage.removeItem('qrdad-user');
        window.location.href = '/auth/login'
    }
});

export const initServer = (server: AxiosInstance) => {
    const userToken = localStorage.getItem('qrdad-user');
    if(userToken != null) {
        const token = JSON.parse(userToken);
        server.defaults.headers.common['Authorization'] = `Bearer ${token.token}`
    }
}