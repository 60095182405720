import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import weekday from 'dayjs/plugin/weekday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(weekday);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

function convertUTCDateToLocalDate(date: Date) {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}

function currentUTCTime(): string {
    return dayjs.utc().format('YYYY-MM-DD[T]HH:mm:ss')
}

function getUTCTimeFromNow(date: string): string {
    const utc = date[date.length - 1] === 'Z' ? date : date + 'Z';
    return dayjs(utc).fromNow();
}

function formatDate(date: any): string {
    return dayjs(date).format('MM/DD/YYYY');
}

function dynamicFormat(date: any, format: string): string {
    return dayjs(date).format(format);
}

function formatDateTime(date: any): string {
    return dayjs(date).format('DD-MMM-YYYY HH:mm:ss');
}

function formatDateTimePPTZ(date: any): string {
    return dayjs(date).format('DD-MMM-YYYY HH:mm A UTCZZ');
}

function convertSeconds(secondlengths: string) {
    const seconds = parseInt(secondlengths, 10);

    if (isNaN(seconds)) {
        throw new TypeError('Invalid value sent to convert-seconds');
    }
    const results: any = {};
    const hours = Math.floor(seconds / 60 / 60);
    results.hours = hours > 9 ? hours.toString() : `0${hours}`;
    const minutes = Math.floor((seconds / 60) % 60)
    results.minutes = minutes > 9 ? minutes.toString() : `0${minutes}`;
    const realSeconds = Math.floor(seconds % 60);
    results.seconds = realSeconds > 9 ? realSeconds.toString() : `0${realSeconds}`;

    return results;
}

function getSeconds(hours: number, minutes: number, seconds: number) {
    let totalSeconds = seconds;
    const secondsFromMinutes = minutes * 60;
    const secondsFromHours = hours * 3600;

    totalSeconds += secondsFromHours + secondsFromMinutes;
    return totalSeconds;
}

function fromNow(time: string) {
    return dayjs(time).fromNow();
}


export { 
    dayjs, 
    duration,
    formatDateTimePPTZ,
    convertUTCDateToLocalDate,
    currentUTCTime,
    getUTCTimeFromNow,
    formatDate,
    formatDateTime,
    convertSeconds,
    getSeconds,
    dynamicFormat,
    fromNow
};