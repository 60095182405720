

































import {Vue, Component} from 'vue-property-decorator';
import CreateFolder from './create.vue';
import { Modal } from 'bootstrap'
import { fetchFolders, getFolders, getQuota } from '@/store/folderState';


@Component({ components: { CreateFolder } })
export default class Folder extends Vue {
    isFetchingFolders = true;

    get quota() {
        return getQuota(this.$store);
    }

    get folders() {
        return getFolders(this.$store);
    }

    openFolderContent(folderId: string) {
        this.$router.push({ name: 'Folder Content', params: { id: folderId } });
    }

    mounted() {
        fetchFolders(this.$store)
            .then(() => {
                this.isFetchingFolders = false;
            });
    }
    createFolder() {
        new Modal(document.getElementById('createFolderModal')!, {}).show();
    }
}
