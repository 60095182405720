










































import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';
import {Vue, Component} from 'vue-property-decorator';

@Component
export default class Size extends Vue {
    get projectData() {
        return getProjectData(this.$store)
    }

    sizes = [
        200, 300, 400, 500, 600, 700, 800
    ];

    precisions = [
        'L - Smallest',
        'M - Medium',
        'Q - High',
        'H - Best'
    ]

    get size () {
        return this.projectData.projectData.customizations.size;
    }

    selectSize(size: number){
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    size
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get level() {
        return this.projectData.projectData.customizations.level
    }

    setLevel(value: string) {
        const properLevel = value.split(' - ')[0];

        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    level: properLevel
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }
}
