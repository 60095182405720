


























































































































































































































import { Vue, Component } from 'vue-property-decorator';
import ColorPicker from '@/components/colorPicker.vue';
import { getProjectData, updateProjectData } from '@/store/editorState';
import { generateId } from '@/utils/utils';
import ImageUploader from '@/components/imageUploader.vue';

@Component({ components: { ColorPicker, ImageUploader } })
export default class ProfileSettings extends Vue {
    get uploaderKey() {
      return generateId();
    }
    
    proceed() {
        this.$emit('done');
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get profileContent() {
        return this.projectData.projectData.content
    }

      get titleFont(): string {
        return this.profileContent.titleFont
    }

     set titleFont(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    titleFont: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleColor(): string {
        return this.profileContent.titleColor;
    }

    set titleColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    titleColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleSize(): string {
        return this.profileContent.titleSize;
    }

    set titleSize(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    titleSize: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get buttonColor(): string {
        return this.profileContent.buttonColor;
    }

    set buttonColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    buttonColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get pageColor(): string {
        return this.profileContent.pageColor;
    }

    set pageColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    pageColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get facebook(): string {
        const splitValues = this.profileContent.socials.facebook.split('/');
        return splitValues[splitValues.length - 1];
    }

    set facebook(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    socials: {
                        ...this.profileContent.socials,
                        facebook: `https://facebook.com/${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get twitter(): string {
        const splitValues = this.profileContent.socials.twitter.split('/');
        return splitValues[splitValues.length - 1];
    }

    set twitter(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    socials: {
                        ...this.profileContent.socials,
                        twitter: `https://twitter.com/${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get instagram(): string {
        const splitValues = this.profileContent.socials.instagram.split('/');
        return splitValues[splitValues.length - 1];
    }

    set instagram(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    socials: {
                        ...this.profileContent.socials,
                        instagram: `https://instagram.com/${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get snapchat(): string {
       const splitValues = this.profileContent.socials.snapchat.split('/');
       return splitValues[splitValues.length - 1];
    }

    set snapchat(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    socials: {
                        ...this.profileContent.socials,
                        snapchat: `https://snapchat.com/${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get phone(): string {
        return this.profileContent.phone;
    }

    set phone(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    phone: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get logo(): string {
        return this.profileContent.logo;
    }

    set logo(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    logo: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }


    get email(): string {
        return this.profileContent.email;
    }

    set email(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    email: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get fullName(): string {
        return this.profileContent.fullName;
    }

    set fullName(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    fullName: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get website(): string {
        return this.profileContent.website;
    }

    set website(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    website: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get company(): string {
        return this.profileContent.company;
    }

    set company(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    company: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get job(): string {
        return this.profileContent.job;
    }

    set job(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    job: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get city(): string {
        return this.profileContent.city;
    }

    set city(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    city: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get address(): string {
        return this.profileContent.address;
    }

    set address(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    address: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get lState(): string {
        return this.profileContent.lState;
    }

    set lState(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    lState: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get country(): string {
        return this.profileContent.country;
    }

    set country(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    country: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get linkedin(): string {
       const splitValues = this.profileContent.socials.linkedin.split('/');
       return splitValues[splitValues.length - 1];
    }

    set linkedin(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    socials: {
                        ...this.profileContent.socials,
                        linkedin: `https://linkedin.com/in/${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }
    
    get tiktok(): string {
        const splitValues = this.profileContent.socials.tiktok.split('/');
        return splitValues[splitValues.length - 1].replaceAll('@', '');
    }

    set tiktok(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.profileContent,
                    socials: {
                        ...this.profileContent.socials,
                        tiktok: `https://tiktok.com/@${this.cleanAt(value)}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    cleanAt(value: string) {
        return value.replaceAll('@', '');
    }

}
