






















import {Vue, Component} from 'vue-property-decorator';
import { getParsedCountryCode } from './utils';
import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';
import CountryCodeSelector from '@/components/countryCode.vue';

@Component({ components: { CountryCodeSelector } })
export default class Call extends Vue {

    get projectData() {
        return getProjectData(this.$store);
    }

    get codes() {
        return getParsedCountryCode();
    }

    get countryCode() {
        return this.projectData.projectData.content.countrycodetel;
    }

    set countryCode(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    countrycodetel: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get telephone() {
        return this.projectData.projectData.content.tel;
    }

    set telephone(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    tel: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    proceed() {
        if(this.projectData.projectData.content.tel.length == 0){
            this.$toast.error('Enter phone number');
            return;
        }

         if(this.projectData.projectData.content.countrycodetel.length == 0){
            this.$toast.error('Enter country code');
            return;
        }

        this.$emit('done')
    }
}
