














































































































































import {Vue, Component} from 'vue-property-decorator';
import ColorPicker from '@/components/colorPicker.vue';
import { getProjectData, updateProjectData } from '@/store/editorState';
import { generateId } from '@/utils/utils';
import ImageUploader from '@/components/imageUploader.vue';
import { formatDate } from '@/utils/dayjs';
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

@Component({ components: { ColorPicker, ImageUploader, Datetime } })
export default class Settings extends Vue {
    proceed() {
       this.$emit('done')
    }

    get uploaderKey() {
      return generateId();
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get eventContent() {
        return this.projectData.projectData.content
    }

      get titleFont(): string {
        return this.eventContent.titleFont
    }

     set titleFont(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    titleFont: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleColor(): string {
        return this.eventContent.titleColor;
    }

    set titleColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    titleColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleSize(): string {
        return this.eventContent.titleSize;
    }

    set titleSize(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    titleSize: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get pageColor(): string {
        return this.eventContent.pageColor;
    }

    set pageColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    pageColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get bannerImage(): string {
        return this.eventContent.bannerImage;
    }

    set bannerImage (value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    bannerImage: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get title(): string {
        return this.eventContent.title;
    }

    set title(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    title: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get description(): string {
        return this.eventContent.description;
    }

    set description(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    description: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get eventType(): string {
        return this.eventContent.eventType;
    }


    set eventType(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    eventType: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get buttonText(): string {
        return this.eventContent.buttonText;
    }


    set buttonText(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    buttonText: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get venue(): string {
        return this.eventContent.venue;
    }


    set venue(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    venue: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get password(): string {
        return this.eventContent.password;
    }


    set password(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    password: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get eventLink(): string {
        return this.eventContent.eventLink;
    }


    set eventLink(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    eventLink: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get startTime(): string {
        return this.eventContent.startTime;
    }


    set startTime(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    startTime: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get endTime(): string {
        return this.eventContent.endTime;
    }


    set endTime(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    endTime: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get buttonLink(): string {
        return this.eventContent.buttonLink;
    }


    set buttonLink(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    buttonLink: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get callToAction(): string {
        return this.eventContent.linkButtonCta;
    }


    set callToAction(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.eventContent,
                    linkButtonCta: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

}
