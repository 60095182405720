import { dynamicPageConfig } from "@/views/editor/projectData";

export type socialMediaConfig = dynamicPageConfig & {
    socials: {
        facebook: string,
        instagram: string,
        twitter: string,
        snapchat: string,
        tiktok: string,
        linkedin: string,
    },
    website: string,
    titleColor: string,
    titleFont: string,
    titleSize: string
}

export const socialMediaDefaults: socialMediaConfig = {
    pageColor: '#027bff',
    socials: {
        facebook: '',
        instagram: '',
        twitter: '',
        snapchat: '',
        linkedin: '',
        tiktok: ''
    },
    website: '',
    titleColor: '#fff',
    titleFont: '',
    titleSize: '24',
}

export const socialMediaColors = {
    facebook: '#1877f2',
    twitter: '#1da1f2',
    instagram: '#5851db',
    snapchat:'#fffc00',
    linkedin: '#0077b5',
    tiktok: '#000000'
}