import countryCodes from 'country-codes-list';

export const getParsedCountryCode = () => {

    const countryCodesList = countryCodes.customArray({ name: '{countryNameEn}', value: '{countryCallingCode}' });
    return countryCodesList;
}

export const currencies = () => {
    return [
        {
            name: 'United States Dollar',
            code: 'USD',
            symbol: '$'
        },
        {
            symbol: '$',
            code: 'AUD',
            name: 'Australian Dollar',
        },

        {
            symbol: 'R$',
            code: 'BRL',
            name: 'Brazilian Real'
        },
        {
            name: 'Canadian Dollar',
            code: 'CAD',
            symbol: '$'
        },
        {
            name: 'Chinese Renmenbi',
            code: 'CNY',
            symbol: '¥',
        },
        {
            name: 'Czech Koruna',
            code: 'CZK',
            symbol: 'Kč',
        },
        {
            name: 'Danish Krone',
            code: 'DKK',
            symbol: 'kr',
        },
        {
            name: 'Euro',
            code: 'EUR',
            symbol: '€',
        },
        {
            name: 'Hong Kong Dollar',
            code: 'HKD',
            symbol: '$'
        },
        {
            name: 'Hungarian Forint',
            code: 'HUF',
            symbol: 'Hf'
        },
        {
            name: 'Israeli New Shekel',
            code: 'ILS',
            symbol: '₪'
        },
        {
            name: 'Japanese Yen',
            code: 'JPY',
            symbol: '¥'
        },
        {
            name: 'Malaysian Ringgit',
            code: 'MYR',
            symbol: 'RM'
        },
        {
            name: 'Mexican peso',
            code: 'MXN',
            symbol: 'RM'
        },
        {
            name: 'New Taiwan Dollar',
            code: 'NT$',
            symbol: '$'
        },
        {
            name: 'New Zealand Dollar',
            code: 'NZD',
            symbol: '$'
        },
        {
            name: 'Philippine peso',
            code: 'PHP',
            symbol: '₱'
        },
        {
            name: 'Polish Poty',
            code: 'PLN',
            symbol: 'zł',
        },
        {
            name: 'Pound sterling',
            code: 'GBP',
            symbol: '£'
        },
        {
            name: 'Russian Ruble',
            code: 'RUB',
            symbol: '₽'
        },
        {
            name: 'Singapore Dollar',
            code: 'SGD',
            symbol: 'S$'
        },
        {
            name: 'Swedish Krona',
            code: 'SEK',
            symbol: 'kr'
        },
        {
            name: 'Swiss Franc',
            code: 'CHF',
            symbol: 'CHF'
        },
        {
            name: 'Thai baht',
            code: 'THB',
            symbol: '฿'
        },
    ];

}