import Vue from 'vue';
import Vuex from 'vuex';
import { ApplicationState } from './applicationState';
import { authState } from './authState';
import { customizeState } from './customizeState';
import { editorState } from './editorState';
import { finderState } from './finderState';
import { folderState } from './folderState';
import { projectState } from './projectState';
import { uploaderState } from './uploaderState';
import { whitelabelState } from './whitelableState';
import { customizerState } from './customizerState';

Vue.use(Vuex)

export const createStore = () => new Vuex.Store<ApplicationState>({
  modules: {
    auth: authState,
    uploader: uploaderState,
    folder: folderState,
    editor: editorState,
    customize: customizeState,
    project: projectState,
    finder: finderState,
    whitelabel: whitelabelState,
    customizer: customizerState
  }
});
