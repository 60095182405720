

























































import {Vue, Component} from 'vue-property-decorator';
import { getProjectData, updateProjectData, updateStepIndex } from '@/store/editorState';
import ScanPreview from './scanPreview.vue';
import { dynamicScanUrl } from '@/utils/server';
import { daddyEvents, daddyEventBus } from '@/utils/eventBus';
import { getFolders } from '@/store/folderState';

@Component({ components: { ScanPreview } })
export default class DownloadQrCode extends Vue {

    goBack() {
        updateStepIndex(this.$store, 2);
    }

   get folders() {
      return getFolders(this.$store);
    }
   
   get projectData() {
      return getProjectData(this.$store);
   }

   get qrType() {
      return this.projectData.projectData.content.type;
   }

   get projectTitle() {
      return this.projectData.title;
   }

   set projectTitle(value) {
      const payload = {
         ...this.projectData,
         title: value
      }
      updateProjectData(this.$store, payload)
   }

    get selectedFolder() {
        return this.projectData.folder.name;
    }

   isActiveType(type: string) {
      return type == this.qrType;
   }

   mounted() {
      if(this.qrType == 'static | dynamic'){
         this.setType('static')
      }
   }

   setSelectedFolder(folder: any) {
        const payload = {
            ...this.projectData,
            folder: {
                name: folder.name,
                id: folder.id
            },
            folderId: folder.id
        }
        updateProjectData(this.$store, payload)
    }

   setType(type: string) {
      
      if(this.projectData.category != 'google' && this.projectData.category != 'website') {
         if(this.projectData.projectData.content.type == 'dynamic' && type != 'dynamic') {
            this.$toast.error('This QR Code can only be dynamic');
            return;
         }

         if(this.projectData.projectData.content.type == 'static' && type != 'static'){
            this.$toast.error('This QR code can only be static')
            return;
         }
      }

      const payload = {
          ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    link: type == 'static' ? this.projectData.projectData.content.websiteUrl : this.getDynamicUrl(),
                    type
                },
            }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
   }

   getDynamicUrl() {
      return `${dynamicScanUrl}/${this.projectData.scanCode}`
   }
}
