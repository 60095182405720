import { appQrConfigDefaults } from "../enter-information/appQr/appQr";
import { audioQrDefaults } from "../enter-information/audioQr/audioQr";
import { couponDefaults } from "../enter-information/coupon/coupon";
import { eventDefaults } from "../enter-information/event/event";
import { feedbackDefaults } from "../enter-information/feedback/feedback";
import { imageDefaults } from "../enter-information/image/image";
import { menuDefaults } from "../enter-information/menu/menu";
import { pdfDefaults } from "../enter-information/pdf/pdf";
import { profileConfigDefaults } from "../enter-information/profile/profile";
import { socialMediaDefaults } from "../enter-information/social-media/socialMedia";
import { videoDefaults } from "../enter-information/videos/video";

export const categories = [
   
   { 
        name: 'Website QR Code',
        description: 'Redirect user to website when scanned',
        icon: 'url.png',
        slug: 'website',
        type: 'static | dynamic',
        section: '#static-link'
    },
    { 
        name: 'Call QR Code',
        description: 'Make a call',
        icon: 'url.png',
        slug: 'call',
        type: 'static',
        section: '#tel'
    },

    { 
        name: 'SMS QR Code',
        description: 'Send a SMS',
        icon: 'url.png',
        slug: 'sms',
        type: 'static',
        section: '#sms'
    },

    { 
        name: 'Text QR Code',
        description: 'Send a text',
        icon: 'url.png',
        slug: 'text-content',
        type: 'static',
        section: '#text'
    },

    { 
        name: 'Paypal QR Code',
        description: 'Generate a PayPal transaction',
        icon: 'url.png',
        slug: 'paypal',
        type: 'static',
        section: '#paypal'
    },

    { 
        name: 'Bitcoin QR Code',
        description: 'Request Crypto Payments',
        icon: 'url.png',
        slug: 'bitcoin',
        type: 'static',
        section: '#bitcoin'
    },

    { 
        name: 'WiFi QR Code',
        description: 'Connect to WiFi',
        icon: 'url.png',
        slug: 'wi-fi',
        type: 'static',
        section: '#wifi'
    },

    { 
        name: 'Email QR Code',
        description: 'Send an email',
        icon: 'url.png',
        slug: 'email',
        type: 'static',
        section: '#email'
    },
    { 
        name: 'Whatsapp QR Code',
        description: 'Send a whatsapp Message',
        icon: 'url.png',
        slug: 'whatsapp',
        type: 'static',
        section: '#whatsapp'
    },
    {
        name: 'Google Maps QR Code',
        description: 'Display a location on a map when scanned',
        icon: 'url.png',
        slug: 'google',
        type: 'static | dynamic',
        section: '#static-link'
    },
    // {
    //     name: 'Audio QR Code',
    //     description: 'Redirects user to uploaded audio files when scanned',
    //     icon: 'url.png',
    //     slug: 'audio',
    //     type: 'dynamic'
    // },
    {
        name: 'Event QR Code',
        description: 'Displays event details when scanned',
        icon: 'url.png',
        slug: 'event-qr',
        type: 'dynamic'
    },

    // {
    //     name: 'Wedding QR Code',
    //     description: 'Displays wedding details when scanned',
    //     icon: 'url.png',
    //     slug: 'wedding',
    //     type: 'dynamic'
    // },

    {
        name: 'Images QR Code',
        description: 'Share your images',
        icon: 'url.png',
        slug: 'image-qr',
        type: 'dynamic'
    },

    {
        name: 'Videos QR Code',
        description: 'Share your videos',
        icon: 'url.png',
        slug: 'videos-qr',
        type: 'dynamic'
    },

    {
        name: 'Document QR Code',
        description: 'Share your documents',
        icon: 'url.png',
        slug: 'pdf-qr',
        type: 'dynamic'
    },

    {
        name: 'App QR Code',
        description: 'Download Apps, Android and IOS',
        icon: 'url.png',
        slug: 'app-qr',
        type: 'dynamic'
    },

    {
        name: 'Coupon QR Code',
        description: 'Discount and coupon offers',
        icon: 'url.png',
        slug: 'coupon',
        type: 'dynamic'
    },

    {
        name: 'Audio QR Code',
        description: 'Share audio files for download',
        icon: 'url.png',
        slug: 'audio-qr',
        type: 'dynamic'
    },

    {
        name: 'Profile Card QR Code',
        description: 'Personal custom page',
        icon: 'url.png',
        slug: 'profile',
        type: 'dynamic'
    },

    {
        name: 'Feedback QR Code',
        description: 'Ask for feedback and reviews',
        icon: 'url.png',
        slug: 'feedback',
        type: 'dynamic'
    },

    {
        name: 'Social Media QR Code',
        description: 'A page for all your social media',
        icon: 'url.png',
        slug: 'social-media',
        type: 'dynamic'
    },
    {
        name: 'Menu QR Code',
        description: 'A menu page for restaurants',
        icon: 'url.png',
        slug: 'menu-qr',
        type: 'dynamic'
    },
];

export const dynamicCategoryContentDefaults = {
    "app-qr": appQrConfigDefaults,
    "social-media": socialMediaDefaults,
    'profile': profileConfigDefaults,
    'coupon': couponDefaults,
    'feedback': feedbackDefaults,
    'audio-qr': audioQrDefaults,
    'event-qr': eventDefaults,
    'image-qr': imageDefaults,
    'videos-qr': videoDefaults,
    'pdf-qr': pdfDefaults,
    'menu-qr': menuDefaults
}