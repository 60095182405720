











import {Vue, Component} from 'vue-property-decorator';
import MenuSettings from './settings.vue';
import Preview from './preview.vue';

@Component({ components: { MenuSettings, Preview } })
export default class Menu extends Vue {
    done() {
      this.$emit('done');
    }
}
