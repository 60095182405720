




















































import { getProjectData, updateProjectData } from '@/store/editorState';
import {Vue, Component} from 'vue-property-decorator';
import ColorPicker from '@/components/colorPicker.vue';
import { generateId } from '@/utils/utils';
import ImageUploader from '@/components/imageUploader.vue';

@Component({ components: { ColorPicker, ImageUploader } })
export default class FeedbackSettings extends Vue {

    proceed() {
        this.$emit('done')
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get feedbackContent() {
        return this.projectData.projectData.content
    }

    get uploaderKey() {
        return generateId();
    }

    get pageColor(): string {
        return this.feedbackContent.pageColor;
    }

    set pageColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.feedbackContent,
                    pageColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get title() {
        return this.feedbackContent.title;
    }

    set title(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.feedbackContent,
                    title: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get logo() {
        return this.feedbackContent.logo;
    }

    set logo(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.feedbackContent,
                    logo: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

      get titleFont(): string {
        return this.feedbackContent.titleFont
    }

     set titleFont(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.feedbackContent,
                    titleFont: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleColor(): string {
        return this.feedbackContent.titleColor;
    }

    set titleColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.feedbackContent,
                    titleColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleSize(): string {
        return this.feedbackContent.titleSize;
    }

    set titleSize(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.feedbackContent,
                    titleSize: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }
}
