































































import { Vue, Component } from "vue-property-decorator";
import { Modal } from 'bootstrap';
import NewUserModal from "./newUserModal.vue";
import DeleteUserModal from "./deleteUserModal.vue";
import { getWhitelabel, fetchWhitelabelUsers, getWhitelabelUsers, fetchWhitelabel, deleteUser } from '@/store/whitelableState';
import { formatDateTime } from '@/utils/dayjs';
import EditUserModal from "./editUserModal.vue";

@Component({ components: { NewUserModal, DeleteUserModal, EditUserModal } })
export default class WhitelabelUsers extends Vue {

  selectedUser: any | null = null;

  get whitelabel(){
    return getWhitelabel(this.$store);
  }

  get whitelabelUsers(){
    return getWhitelabelUsers(this.$store);
  }

  mounted() {
    if(this.whitelabelUsers.length == 0){
      this.$toast.info('Fetching whitelabel users...');
    }

    fetchWhitelabel(this.$store).then(() => {
        fetchWhitelabelUsers(this.$store, this.whitelabel.id);
    })
  }

  openNewUserModal() {
    new Modal(document.getElementById('newUserModal')!).show();
  }

  openDeleteModal(user: any) {
    this.selectedUser = user;
    new Modal(document.getElementById('deleteUserModal')!).show();
  }

   openEditModal(user: any) {
    this.selectedUser = user;
    new Modal(document.getElementById('editUserModal')!).show();
  }

  formatDate(date: string){
    return formatDateTime(date);
  }

  deleteUser() {
      deleteUser(this.$store, { id: this.selectedUser.id })
          .then(() => this.$toast.success('User deleted'))
          .catch(() => this.$toast.error('User not deleted'));
  }
}
