













































import { getJobs, fetchJobs, getPinnedJobs, fetchPinnedJobs, clearPinned } from '@/store/finderState';
import {Vue, Component} from 'vue-property-decorator';
import JobCards from './jobCards.vue';
import PinnedJobCards from './pinnedJobCards.vue';
import HorizontalLoader from '@/components/loader.vue';

@Component({ components: { JobCards, PinnedJobCards, HorizontalLoader } })
export default class Finder extends Vue {
    get jobs() {
        return getJobs(this.$store);
    }

    get pinnedJobs() {
        return getPinnedJobs(this.$store);
    }

    mounted() {
        fetchJobs(this.$store);
        fetchPinnedJobs(this.$store);
    }

    clearPinned(){
        clearPinned(this.$store);
    }
}
