import { RouteConfig } from 'vue-router'
import Login from '@/views/auth/login.vue'
import PasswordRecovery from '@/views/auth/passwordRecovery.vue'
// import Register from '../views/auth/register.vue'
// import PasswordRecovery from '../views/auth/passwordRecovery.vue'

const authRoutes: Array<RouteConfig> = [
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'auth-layout'
        }
    },

    // {
    //     path: '/auth/register',
    //     name: 'Register',
    //     component: Register,
    //     meta: {
    //         layout: 'auth-layout'
    //     }
    // },

    {
        path: '/auth/password-recovery',
        name: 'PasswordRecovery',
        component: PasswordRecovery,
        meta: {
            layout: 'auth-layout'
        }
    },
];

export default authRoutes;