






















































import { createWhitelabelUser, getWhitelabel } from "@/store/whitelableState";
import { Modal } from "bootstrap";
import { Vue, Component } from "vue-property-decorator";
import { v4 as uuid } from 'uuid';
import { userRoles } from '@/utils/userRoles'

@Component
export default class NewUserModal extends Vue {
  firstName = '';
  lastName = '';
  email = '';
  password = '';
  userRole = '';

  get userRoles() {
    return userRoles;
  }

  get whitelabel() {
    return getWhitelabel(this.$store);
  }

  create() {
    if(this.validate()) {
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        whiteLabelId: this.whitelabel.id,
        isWhiteLabel: true,
        id: uuid(),
        isActive: true,
        userRoles: this.userRole
      }

      createWhitelabelUser(this.$store, payload).then(() => {
        this.$toast.success('User created successfully')
        Modal.getInstance(document.getElementById('newUserModal')!)!.hide();
      }).catch(err => {
        this.$toast.error('Something went wrong. Try again');
      })
    }
  }

  validate() {
    if(this.firstName.length == 0){
      this.$toast.error('Enter first name');
      return false;
    }

    if(this.email.length == 0){
      this.$toast.error('Enter email');
      return false;
    }

    if(this.password.length == 0){
      this.$toast.error('Enter password');
      return false;
    }

    if(this.password.length < 8) {
      this.$toast.error('password length must be atleast 8 characters')
      return false;
    }

    return true;
  }
}
