import { dynamicPageConfig } from "@/views/editor/projectData";

export type profileConfig = dynamicPageConfig & {
    socials: {
        facebook: string,
        instagram: string,
        twitter: string,
        snapchat: string,
        tiktok: string,
        linkedin: string,
    },
    website: string,
    fullName: string,
    email: string,
    address: string,
    city: string,
    country: string,
    logo: string,
    lState: string,
    phone: string,
    company: string,
    job: string,
    titleColor: string,
    buttonColor?: string,
    titleFont: string,
    titleSize: string
}

export const profileConfigDefaults: profileConfig = {
    pageColor: '#027bff',
    buttonColor: '#027bff',
    socials: {
        facebook: '',
        instagram: '',
        twitter: '',
        snapchat: '',
        linkedin: '',
        tiktok: ''
    },
    website: '',
    fullName: '',
    email: '',
    lState: '',
    logo: '',
    country: '',
    city: '',
    address: '',
    phone: '',
    company: '',
    job: '',
    titleColor: '#000',
    titleFont: '',
    titleSize: '24',
}