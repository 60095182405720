

























import {Vue, Component} from 'vue-property-decorator';

@Component
export default class Agency extends Vue {
    agencyKits = [
        {
            name: 'Agency Website',
            link: 'https://docs.google.com/document/d/1-qRTw2kemO7oPUZGQGDUxyJ2XWb8vV0BdPCCKzUwykU/edit?usp=sharing'
        },
        {
            name: 'DFY Graphics',
            link: 'https://drive.google.com/file/d/1IQifG64VM5vunVHgMIr-dfyLU14PtOWT/view?usp=sharing'
        }, 
          {
            name: 'Articles',
            link: 'https://drive.google.com/file/d/1cLdk2LoMExb9fZVTZVW_z96EH25wqRtG/view?usp=sharing'
        },
        {
            name: 'Email Swipes',
            link: 'https://drive.google.com/file/d/1xdQi68wKVHXGvisFZ-hAW7Cb7TmknruS/view?usp=sharing'
        },
        {
            name: 'Ad Copies',
            link: 'https://drive.google.com/file/d/1RKe6jCUzI5ZYDTu8u-PIaUeQ5OLtX25m/view?usp=sharing'
        },
         {
            name: 'FB Ad Graphics',
            link: 'https://drive.google.com/file/d/16y3t2jCWLgyHQdDYosdPfl1GijBVy2nD/view?usp=sharing'
        },

        {
            name: 'Proposal Templates',
            link: 'https://drive.google.com/file/d/1BMpaX3wkc8a7yiF76W1IXo3t1sbVQKGb/view?usp=sharing'
        },
         {
            name: 'Telemarketing Scripts',
            link: 'https://drive.google.com/drive/folders/1K1zfKSFflq5TXGYGxJ1GzBK_rOQw94W_?usp=sharing'
        },
        {
            name: 'Brand Templates',
            subtext: 'Invoice, letterhead, brochure, ID card, flyers, business cards',
            link: 'https://drive.google.com/file/d/1IQifG64VM5vunVHgMIr-dfyLU14PtOWT/view?usp=sharing'
        }


            // link: 'https://docs.google.com/document/d/1-qRTw2kemO7oPUZGQGDUxyJ2XWb8vV0BdPCCKzUwykU/edit?usp=sharing',
        //  {
        //     name: 'Agency Website',
        //     link: 'https://drive.google.com/file/d/1C5l5lIKtBpGiW3OuWKqLD_8u26bAuThg/view?usp=sharing'
        // },
       
        // {
        //     name: 'QR Verse Agency Facebook Ads',
        //     link: 'https://drive.google.com/file/d/1RKe6jCUzI5ZYDTu8u-PIaUeQ5OLtX25m/view?usp=sharing'
        // },
        // {
        //     name: 'Proposal Templates',
        //     link: 'https://drive.google.com/file/d/1BMpaX3wkc8a7yiF76W1IXo3t1sbVQKGb/view?usp=sharing'
        // },
       
    ];

    open(link: string) {
        window.open(link, '_blank');
    }
}
