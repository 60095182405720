




















import { getProjectData } from '@/store/editorState';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Preview extends Vue {
    get projectData() {
        return getProjectData(this.$store);
    }

    get documentQrContent() {
        return this.projectData.projectData.content;
    }

    get pageColor() {
        return {
            backgroundColor: this.documentQrContent.pageColor
        }
    }

    get documentFiles() {
        return this.documentQrContent.documents
    }

    get title() {
        return this.documentQrContent.title;
    }

    get textStyle() {
        return {
            fontFamily: this.documentQrContent.titleFont,
            fontSize: `${this.documentQrContent.titleSize}px`,
            color: `${this.documentQrContent.titleColor} !important`
        }
    }
}
