



















































import { getProjectData } from '@/store/editorState';
import { Component, Vue } from 'vue-property-decorator';
import { dayjs } from '@/utils/dayjs'

@Component
export default class Preview extends Vue {
    get projectData() {
        return getProjectData(this.$store);
    }

    get pageColor() {
        return {
            backgroundColor: this.eventContent.pageColor
        }
    }

    get eventContent() {
        return this.projectData.projectData.content;
    }

    get eventTitle() {
        return this.eventContent.title;
    }

    get description() {
        return this.eventContent.description;
    }

    get externalLink() {
        return this.eventContent.buttonLink;
    }

    get bannerImage() {
        return this.eventContent.bannerImage;
    }

    get eventType() {
        return this.eventContent.eventType;
    }

    get venue() {
        return this.eventContent.venue;
    }

    get callToAction() {
        return this.eventContent.linkButtonCta;
    }

    get eventLink() {
        return this.eventContent.eventLink;
    }

    get password() {
        return this.eventContent.password;
    }

    get startTime() {
        return dayjs(this.eventContent.startTime).format('DD MMM, YYYY. HH:mm a')
    }

     get endTime() {
        return dayjs(this.eventContent.endTime).format('DD MMM, YYYY. HH:mm a')
    }

    get buttonText() {
        return this.eventContent.buttonText
    }

    get textStyle() {
        return {
            fontFamily: this.eventContent.titleFont,
            fontSize: `${this.eventContent.titleSize}px`,
            color: `${this.eventContent.titleColor} !important`
        }
    }
}
