import { dynamicPageConfig } from "@/views/editor/projectData";

export type appQrConfig = dynamicPageConfig & {
    name: string,
    logo: string,
    description: string,
    website: string,
    appLinks: {
        android: string,
        ios: string
    },
    titleColor: string,
    titleFont: string,
    titleSize: string
}

export const appQrConfigDefaults: appQrConfig = {
    pageColor: '#027bff',
    logo: '',
    name: 'Airbnb',
    description: 'American vacation rental online marketplace company based in San Francisco, California, United States',
    website: 'https://airbnb.com',
    appLinks: {
        android: 'https://play.google.com/store/apps/details?id=com.airbnb.android&hl=en&gl=US',
        ios: 'https://apps.apple.com/us/app/airbnb/id401626263'
    },
    titleColor: '#000',
    titleFont: 'Raleway',
    titleSize: '40',
}