































import { Vue, Component, Prop } from "vue-property-decorator";
import { Modal } from 'bootstrap';

@Component
export default class DeleteUserModal extends Vue {
    @Prop({ default: () => ({}) }) user!: any;

    deleteUser() {
      this.$emit('deleteUser')
      this.cancel()
    }

    cancel() {
        Modal.getInstance(document.getElementById('deleteUserModal')!)!.hide();
    }
}
