




























import { Vue, Component } from "vue-property-decorator";
import { getFolders } from '@/store/folderState';
import { getProjects } from '@/store/projectState';
import ProjectCard from "../projects/projectCard.vue";

@Component({ components: { ProjectCard } })
export default class FolderContent extends Vue {
  get folder() {
    const folderId = this.$route.params.id;
    const folder = getFolders(this.$store).find((folder: any) => {
      return folder.id == folderId;
    });

    return folder;
  }

  get folderProjects() {
    const folderId = this.$route.params.id;
    const projects = getProjects(this.$store);
    const folderProjects = projects.filter(project => {
      return project.folderId == folderId;
    })

    return folderProjects;
  }
}
