export type projectData = {
    folderId?: string,
    category: string,
    projectData: projectDataContent,
    title: string,
    scanCode: string,
    qrCodeUrl: {
        png: string,
        svg: string
    },
    id: string
    folder: {
        name: string,
        id: string
    }
}

type projectDataContent = {
    content: any;
    customizations: {
        backcolor: string;
        transparent: string | null;
        frontcolor: string;
        gradient_color: string;
        gradient: string | null;
        radial: string | null;
        pattern: string;
        marker_out: string;
        marker_in: string;
        marker_out_color: string;
        marker_in_color: string;
        markers_color: string | null;
        logo_size: string;
        outer_frame: string;
        framelabel: string;
        custom_frame_color: string;
        label_font: string;
        label_text_size: string;
        framecolor: string;
        size: number;
        level: string;
        option_logo: string;
        no_logo_bg: string | null;
    }
}

export const customizationDefaults = {
    pattern: 'default',
    marker_in: 'default',
    marker_out: 'default',
    backcolor: '#fff',
    transparent: null,
    gradient_color: '#000',
    gradient: null,
    radial: null,
    size: 600,
    level: 'M',
    framecolor: '#000',
    framelabel: 'SCAN ME',
    label_font: 'AbrilFatface',
    marker_in_color: '#000',
    marker_out_color: '#000',
    logo_size: '100',
    label_text_size: '100',
    frontcolor: '#000',
    outer_frame: 'none',
    option_logo: 'none',
    custom_frame_color: '#000',
    markers_color: null,
    no_logo_bg: null,
}

export type dynamicPageConfig = {
    pageColor: string,
}