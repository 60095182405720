






































































































import { Vue, Component } from 'vue-property-decorator';
import ColorPicker from '@/components/colorPicker.vue';
import { getProjectData, updateProjectData } from '@/store/editorState';

@Component({ components: { ColorPicker } })
export default class SocialMediaSettings extends Vue {
    proceed() {
        this.$emit('done');
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get socialMediaContent() {
        return this.projectData.projectData.content;
    }

    get pageColor(): string {
        return this.socialMediaContent.pageColor;
    }

    set pageColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.socialMediaContent,
                    pageColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get facebook(): string {
        const splitValues = this.socialMediaContent.socials.facebook.split('/');
        return splitValues[splitValues.length - 1];
    }

    set facebook(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.socialMediaContent,
                    socials: {
                        ...this.socialMediaContent.socials,
                        facebook: `https://facebook.com/${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get twitter(): string {
        const splitValues = this.socialMediaContent.socials.twitter.split('/');
        return splitValues[splitValues.length - 1];
    }

    set twitter(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.socialMediaContent,
                    socials: {
                        ...this.socialMediaContent.socials,
                        twitter: `https://twitter.com/${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get instagram(): string {
        const splitValues = this.socialMediaContent.socials.instagram.split('/');
        return splitValues[splitValues.length - 1];
    }

    set instagram(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.socialMediaContent,
                    socials: {
                        ...this.socialMediaContent.socials,
                        instagram: `https://instagram.com/${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get snapchat(): string {
       const splitValues = this.socialMediaContent.socials.snapchat.split('/');
       return splitValues[splitValues.length - 1];
    }

    set snapchat(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.socialMediaContent,
                    socials: {
                        ...this.socialMediaContent.socials,
                        snapchat: `https://snapchat.com/${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get website(): string {
        return this.socialMediaContent.website;
    }

    set website(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.socialMediaContent,
                    website: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get linkedin(): string {
       const splitValues = this.socialMediaContent.socials.linkedin.split('/');
       return splitValues[splitValues.length - 1];
    }

    set linkedin(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.socialMediaContent,
                    socials: {
                        ...this.socialMediaContent.socials,
                        linkedin: `https://linkedin.com/in/${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }
    
    get tiktok(): string {
        const splitValues = this.socialMediaContent.socials.tiktok.split('/');
        return splitValues[splitValues.length - 1].replaceAll('@', '');
    }

    set tiktok(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.socialMediaContent,
                    socials: {
                        ...this.socialMediaContent.socials,
                        tiktok: `https://tiktok.com/@${value}`
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }


}
