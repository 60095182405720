















import { String } from 'lodash';
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class CategoryCard extends Vue {
    @Prop({ default: () => ({}) }) category!: any;

    get types() {
        const types = this.category.type.split('|');
        return types;
    }

    getColor(type: string){
        return {
            backgroundColor: type.trim() == 'static' ? 'green' : 'yellow',
            color: type.trim() == 'static' ? 'white' : 'black'
        }
    }
 
    src(image: string) {
        return require(`../../../../assets/images/${image}`);
    }
}
