

















































































import {Vue, Component} from 'vue-property-decorator';
import { fetchProjects, getProjects, getQuota } from '@/store/projectState';
import ProjectCard from './projectCard.vue';
import { fetchFolders } from '@/store/folderState';

@Component({ components: { ProjectCard } })
export default class Project extends Vue {
    searchKeyword = '';
    selectedCategory = 'All';
    selectedType = 'all';

    get projects() {
        const projects = getProjects(this.$store);
        if(this.searchKeyword.length > 0){
            const filteredProject = projects.filter((project: any) => {
                return project.title.toLowerCase().includes(this.searchKeyword.toLowerCase())
            });
            return filteredProject;
        } else {
           return projects;
        }
    }

    get quota() {
        return getQuota(this.$store);
    }

    get filteredProjects() {
        let filteredProjects = this.projects;
        if(this.selectedCategory != 'All'){
            filteredProjects = filteredProjects.filter(project => {
                return project.category == this.selectedCategory
            });
        } 

        if(this.selectedType != 'all') {
            filteredProjects = filteredProjects.filter(project => {
                return project.projectData.content.type == this.selectedType;
            })
        }

        return filteredProjects;
    }

    mounted() {
        if(this.projects.length == 0) {
            this.$toast.info('Fetching your projects...')
        }
        fetchFolders(this.$store);
        fetchProjects(this.$store);
    }

    setCategory(category: string) {
        this.selectedCategory = category;
    }

    setType(type: string) {
        this.selectedType = type;
    }

    isActiveType(type: string) {
        return this.selectedType == type;
    }
}
