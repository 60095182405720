import Vue from 'vue';
import { Store } from 'vuex'
import VueRouter, { RouteConfig } from 'vue-router';
import { ApplicationState } from '../store/applicationState';
import authRoutes from './authRoutes';
import { attemptLoginFromLocalStorage, fetchWorkspaces, getActiveUser, getUser } from '@/store/authState';
import dashboardRoutes from './dashboardRoutes';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/', redirect: '/auth/login',
    },
    ...authRoutes,
    ...dashboardRoutes,
];

const routeContains = (path: string, rootPaths: string[]) => {
	const pathIndex = rootPaths.findIndex(rootPath => {
		return path.includes(rootPath)
	});

	return pathIndex !== -1;
}

export const ConfigureRouteGuard = (store: Store<ApplicationState>) => {

    const router = new VueRouter({
        mode: 'history',
        routes
    });

    router.beforeEach(async (to, from, next) => {
        if (routeContains(to.path, ['auth'])) {
			const token = await attemptLoginFromLocalStorage(store);
			if (token) {
				next({ name: 'Dashboard' });
			}
		}

        if(to.meta!.requiresAuth){
            const activeUser = getActiveUser(store);

            if(Object.keys(activeUser).length > 0) {
                next();
            } else {
                const token = await attemptLoginFromLocalStorage(store);
                getUser(store);
                if(token) {
                    // fetchWorkspaces(store);
                    next();
                } else {
                    next({ name: 'Login' })
                }
            }
            
        }

        next();
    });

    return router;
}