





















import {Vue, Component} from 'vue-property-decorator';

@Component
export default class Knowledge extends Vue {
    tutorials = [
        {
            name: 'Create Event QR Code',
            link: 'https://www.youtube.com/embed/hwDoH8paX-s'
        },
        {
            name: 'Create Folder and add QR Code to Folder',
            link: 'https://www.youtube.com/embed/Dr7sLTo7004'
        },
        {
            name: 'QRverse Walkthrough',
            link: 'https://www.youtube.com/embed/S4kaX8gQhXY'
        },
        {
            name: 'How to setup your Whitelabel',
            link: 'https://www.youtube.com/embed/J68Aj2oJ_K0'
        },
    ]
}
