























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { fromNow } from '@/utils/dayjs';
import { getFolders } from '@/store/folderState';
import axios from 'axios';
import download from 'downloadjs';
import ConfirmDeleteModal from './confirmDeleteModal.vue';
import { Modal } from 'bootstrap';
import { editQrCode, updateStepIndex } from '@/store/editorState';
import FeedbackModal from './feedbackModal.vue';
import Copy from 'copy-to-clipboard';
import ScanInfoModal from './scanInfoCard.vue';
import { getActiveUser } from '@/store/authState';

@Component({ components: { ConfirmDeleteModal, FeedbackModal, ScanInfoModal } })
export default class ProjectCard extends Vue {
    @Prop({ default: () => ({}) }) project!: any;

    formatTime() {
        return fromNow(this.project.updatedAt);
    }

    folderName() {
        if (this.folders.length > 0) {
            return this.folders.find(folder => {
                return folder.id == this.project.folderId
            })!.name
        }
    }

    get user() {
        return getActiveUser(this.$store);
    }

    get isNotFE() {
        return this.user.userRoles != 'Frontend'
    }

    get folders() {
        return getFolders(this.$store)
    }

    openFolderContent() {
        this.$router.push({ name: 'Folder Content', params: { id: this.project.folderId } });
    }

    getTypeBackground() {
        return {
            backgroundColor: this.project.projectData.content.type == 'static' ? 'green' : 'yellow',
            color: this.project.projectData.content.type == 'static' ? 'white' : 'black'
        }
    }

    openConfirmDelete() {
        new Modal(document.getElementById(this.project.id)!).show();
    }

    openFeedbackModal() {
        new Modal(document.getElementById(`${this.project.id}_feedback`)!).show();
        // (this.$refs[`${this.project.id}_feedback`] as FeedbackModal).refreshLayout();
    }

    openScanModal() {
        new Modal(document.getElementById(`${this.project.id}_scan`)!).show();
    }

    editQrCode() {
        editQrCode(this.$store, { ...this.project, folder: { id: this.project.folderId, name: this.folderName() } });
        updateStepIndex(this.$store, 1);
        this.$router.push({ name: 'Create' });
    }

    download(type: string) {
        const url = this.project.qrCodeUrl[type]
        const request = axios.get(url, { responseType: 'arraybuffer' })
        request.then((response) => {
            download(response.data, this.project.title, `image/${type == 'svg' ? 'svg+xml' : type}`);
            // resolve(response.data);
        }).catch((error) => {
            // return reject(error)
        });
    }

    copyLink() {
        Copy(`https://code.qrverse.io/${this.project.id}`)
        this.$toast.success('Link Copied');
    }

    shouldShareViaUrl() {
        return
    }

    get scanTimes() {
        return this.project.scanTimes + this.project.scanInfo.length
    }

     shareFacebook() {
        const url = `https://www.facebook.com/sharer/sharer.php?u=https://code.qrverse.io/${this.project.id}`;
        window.open(url, '_blank')
    }
}
