import { Vue } from 'vue-property-decorator';

export default class Base extends Vue {
    isLoading = false;
    loadingMessage = ""
    hasError = false;
    errors: string[] = [];
    hasMessage = false;
    message = '';

    beginLoading(message: string) {
        this.isLoading = true;
        this.hasError = false;
        this.errors = [];
        this.loadingMessage = message;
    }

    setError(errors: string[]) {
        this.hasError = true;
        this.finishLoading();
        this.errors = errors;
    }

    resetError() {
        this.hasError = false;
        this.errors = [];
    }
    
    setTempError(errors: string[], timeout: number) {
        this.setError(errors);
        
        setTimeout(() => {
            this.resetError();
        }, timeout);
    }

    setMessage(message: string) {
        this.hasMessage = true;
        this.message = message;
    }

    resetMessage() {
        this.hasMessage = false;
        this.message = '';
    }

    setTempMessage(message: string, timeout: number) {
        this.setMessage(message);

        setTimeout(() => {
            this.resetMessage();
        }, timeout);
    }

    finishLoading() {
        this.isLoading = false;
        this.loadingMessage = "";
    }
}