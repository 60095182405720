










import { Component } from "vue-property-decorator";
import Base from "./Base"
import ProgressLoadingComponent from "./components/progressLoadingComponent.vue";
import Initializer from './components/initializer.vue';
import { setIsWhitelabel, fetchWhitelabelDetails } from '@/store/customizerState';

@Component({ components: { ProgressLoadingComponent, Initializer } })
export default class App extends Base {
  isInitializing = true;
  mounted() {
    const host = this.cleanHost(window.location.host);
    console.log(window.location.host);
    if(host !== 'app.qrdaddy.io' && host !== 'app.qrverse.io' && host !== 'localhost:8080'){
      setIsWhitelabel(this.$store, true);
      fetchWhitelabelDetails(this.$store, host).then(() => {
        this.isInitializing = false;
      })
    } else {
      setIsWhitelabel(this.$store, false);
      this.isInitializing = false;
    }

  }

  cleanHost(host: string) {
    return host.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
  }
}
