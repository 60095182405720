import randomstring from 'randomstring';

export const generateId = (length = 5) => {
    return randomstring.generate({ charset: 'alphanumeric', length, capitalization: 'lowercase' })
}

export const bytesToSize = (bytes, decimals = 0) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
 }