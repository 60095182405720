import { dynamicPageConfig } from "@/views/editor/projectData";

export type videoConfig = dynamicPageConfig & {
    title: string,
    videoLink: string;
    titleColor: string,
    titleFont: string,
    titleSize: string
}

export const videoDefaults: videoConfig = {
    pageColor: '#027bff',
    title: '',
    videoLink: '',
    titleColor: '#fff',
    titleFont: '',
    titleSize: '24',
}