





























import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';
import {Vue, Component} from 'vue-property-decorator';
import { getParsedCountryCode } from './utils';
import CountryCodeSelector from '@/components/countryCode.vue';

@Component({ components: { CountryCodeSelector } })
export default class SMS extends Vue {
    get codes() {
        return getParsedCountryCode();
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get countryCode() {
        return this.projectData.projectData.content.countrycodesms;
    }

    set countryCode (value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    countrycodesms: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get telephone() {
        return this.projectData.projectData.content.sms;
    }

    set telephone(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    sms: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get message() {
        return this.projectData.projectData.content.bodysms;
    }

    set message(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    bodysms: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }



    proceed() {
         if(this.projectData.projectData.content.sms.length == 0){
            this.$toast.error('Enter phone number');
            return;
        }

         if(this.projectData.projectData.content.countrycodesms.length == 0){
            this.$toast.error('Enter country code');
            return;
        }

        if(this.projectData.projectData.content.bodysms.length == 0){
            this.$toast.error('Enter SMS');
            return;
        }

        this.$emit('done')
    }
}
