

























import { updateStepIndex } from '@/store/editorState';
import { Modal } from 'bootstrap';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class WarningModal extends Vue {
    goBack() {
        this.cancel();
        updateStepIndex(this.$store, 0);
    }

    cancel() {
        Modal.getInstance(document.getElementById('warningModal')!)!.hide();
    }
}
