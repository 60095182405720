

























import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';
import {Vue, Component} from 'vue-property-decorator';
import { logos } from './shapesRepo';
import Loader from "@/components/loader.vue";

@Component({ components: { Loader } })
export default class Logo extends Vue {

    isUploading = false;

    get logos() {
        return logos;
    }

    getLogo(logo: string) {
        return require(`../../../../../assets/svg/qr-logos/${logo}.png`)
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get noLogoBg() {
        const noLogoBg = this.projectData.projectData.customizations.no_logo_bg;
        return noLogoBg && noLogoBg == 'on';
    }

    set noLogoBg(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    no_logo_bg: value ? 'on' : null,
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    fileUpload(e: any) {
        if (this.isUploading == false) {
            const imageFile = e.target.files[0] as File;
            if (imageFile.size > 1000000) {
                this.$toast.error("Media should not be greater than 1MB");
                return;
            }

            this.isUploading = true;
            var reader = new FileReader();
            reader.onloadend = () => {
                this.setOptionlogo(reader.result as string);
                this.isUploading = false;
            }
            reader.readAsDataURL(imageFile);
        }
    }

    setOptionlogo(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    optionlogo: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }
}
