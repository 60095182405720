



































































import { getShowWebinarModal } from '@/store/customizerState';
import { fetchFolders } from '@/store/folderState';
import { fetchProjects, getProjects } from '@/store/projectState';
import { Modal } from 'bootstrap';
import {Vue, Component} from 'vue-property-decorator';
import ProjectCard from '../projects/projectCard.vue';
import WebinarModal from './webinarModal.vue';

@Component({ components: { ProjectCard, WebinarModal } })
export default class Dashboard extends Vue {
	showFinder = false;
	showProjects = false;
	showCreate = false;

	get projects() {
		return getProjects(this.$store).slice(0, 3)
	}

	mounted() {
		if(getShowWebinarModal(this.$store)){
			new Modal(document.getElementById('webinarModal')!).show();
		}
		fetchFolders(this.$store).then(() => {
			fetchProjects(this.$store);
		})
	}

	navigateWebinar() {
		window.open('https://attendee.gotowebinar.com/register/5376795902595684624', '_blank');
	}
}
