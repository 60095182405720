import { render, staticRenderFns } from "./passwordRecovery.vue?vue&type=template&id=c6c4f2f2&scoped=true&"
import script from "./passwordRecovery.vue?vue&type=script&lang=ts&"
export * from "./passwordRecovery.vue?vue&type=script&lang=ts&"
import style0 from "./passwordRecovery.vue?vue&type=style&index=0&id=c6c4f2f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6c4f2f2",
  null
  
)

export default component.exports