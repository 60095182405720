
















import { setShowWebinarModal } from '@/store/customizerState';
import { Modal } from 'bootstrap';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class WebinarModal extends Vue {
    navigateWebinar() {
		window.open('https://attendee.gotowebinar.com/register/5376795902595684624', '_blank');
        this.closeModal();
	}

    stopShowing() {
        setShowWebinarModal(this.$store, false);
        this.closeModal();
    }

    closeModal() {
        Modal.getInstance(document.getElementById('webinarModal')!)!.hide();
    }
}
