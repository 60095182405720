import { ApplicationState } from "./applicationState";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { initServer, SERVER } from "@/utils/server";
import _ from 'lodash'

type FinderContext = ActionContext<FinderState, ApplicationState>

export interface FinderState {
    jobs: any[],
    pinnedJobs: any[],
}

const initialState: FinderState = {
    jobs: [],
    pinnedJobs: []
}

export const finderState = {	
     namespaced: true, 
     state: initialState,
     getters: {
        getJobs(state: FinderState) {
            return state.jobs;
        },

        getPinnedJobs(state: FinderState) {
            return state.pinnedJobs;
        }
     },
     mutations: {
        setJobs(state: FinderState, jobs: any[]) {
            state.jobs = jobs;
        },

        appendPinnedJobs(state: FinderState, job: any[]) {
           state.pinnedJobs.push(...job);
           state.pinnedJobs = _.uniqBy(state.pinnedJobs, 'id')

           console.log(state.pinnedJobs);
        },

        clearPinnedJobs(state: FinderState) {
            state.pinnedJobs = [];
        },

        unpinJob(state: FinderState, jobId: string) {
            state.pinnedJobs = state.pinnedJobs.filter(job => job.id !== jobId);
        }
     },
     actions: {
        fetchJobs(context: FinderContext) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.get('jobs')
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success) {
                        context.commit('setJobs', apiResponse.data);
                        resolve('');
                    } else {
                        reject('')
                    }
                }).catch((err) => {
                    reject(err);
                })
            });
        },

        fetchPinnedJobs(context: FinderContext) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.get('jobs/pinnedJobs')
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success) {
                        context.commit('appendPinnedJobs', apiResponse.data);
                        resolve('');
                    } else {
                        reject('')
                    }
                }).catch((err) => {
                    reject(err);
                })
            });
        },

        pinJob(context: FinderContext, job: any){
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.post('jobs/pin', job)
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        context.commit('appendPinnedJobs', [apiResponse.data])
                    }
                    resolve('done')
                }).catch((err) => {
                    reject(err);
                })
            });
        },

        clearPinned (context: FinderContext) {
            return new Promise((resolve, reject) => {
                const request = SERVER.delete('jobs');

                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        context.commit('clearPinnedJobs')
                    }
                    resolve('done')
                }).catch((err) => {
                    reject(err);
                });
            });
        },

        unpinJob(context: FinderContext, jobId: string) {
            return new Promise((resolve, reject) => { 
                initServer(SERVER)
                const request = SERVER.post('jobs/unpin/', { id: jobId })
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        context.commit('unpinJob', jobId)
                    }
                    resolve('done')
                }).catch((err) => {
                    reject(err);
                })
            })
        }
     },
}

const { read, dispatch } = getStoreAccessors<FinderState, ApplicationState>('finder');

const getters = finderState.getters;
const actions = finderState.actions;

export const getJobs = read(getters.getJobs);
export const getPinnedJobs = read(getters.getPinnedJobs)

export const fetchJobs = dispatch(actions.fetchJobs);
export const pinJob = dispatch(actions.pinJob);
export const fetchPinnedJobs = dispatch(actions.fetchPinnedJobs);
export const clearPinned = dispatch(actions.clearPinned);
export const unpinJob = dispatch(actions.unpinJob);
