









































import {Vue, Component} from 'vue-property-decorator';
import { frames } from './shapesRepo';
import ColorPicker from '../../../../../components/colorPicker.vue';
import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';

@Component({ components: { ColorPicker } })
export default class Frames extends Vue {

    get projectData() {
        return getProjectData(this.$store)
    }

    get frames() {
        return frames;
    }

    get fonts() {
        return [
            'AbrilFatface',
            'Arial',
            'CormorantGaramond',
            'FredokaOne',
            'Galindo',
            'Oleoscript',
            'Shrikhand'
        ]
    }

    getFrames(frame: string){
         return require(`../../../../../assets/svg/qr-frames/${frame}.svg`)
    }

    get frame() {
        return this.projectData.projectData.customizations.outer_frame;
    }

    setOuterFrame(frame: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    outer_frame: frame
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get frameLabel() {
        return this.projectData.projectData.customizations.framelabel;
    }

    set frameLabel(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    framelabel: value
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get frameColor() {
        return this.projectData.projectData.customizations.framecolor;
    }

    set frameColor(value) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    framecolor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get frameLabelFont() {
        return this.projectData.projectData.customizations.label_font
    }

    setFrameLabelFont(font: string) {
         const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: {
                    ...this.projectData.projectData.customizations,
                    label_font: font
                }
            }
        }

        updateProjectData(this.$store, payload);
         daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }
}
