import { ApplicationState } from "./applicationState";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";

type UploaderContext = ActionContext<UploaderState, ApplicationState>

export type UploadedFiles = {
    filename?: string,
    progress: number,
    id: string
}

export interface UploaderState {
   uploadedFiles: UploadedFiles[]
}

const initialState: UploaderState = {
    uploadedFiles: []
}

export const uploaderState = {
    namespaced: true,
    state: initialState,
    getters: {
        getUploadedFiles(state: UploaderState) {
            return state.uploadedFiles
        }
    },

    mutations: {
        addFile(state: UploaderState, file: UploadedFiles) {
            state.uploadedFiles.push(file);
        },

        updateFile(state: UploaderState, payload: UploadedFiles) {
            const uploadedFileIndex = state.uploadedFiles.findIndex(file => file.id == payload.id);
            if(uploadedFileIndex > -1){
                state.uploadedFiles[uploadedFileIndex].progress = payload.progress;
            }
        },

        removeFile(state: UploaderState, id: string) {
            const uploadedFileIndex = state.uploadedFiles.findIndex(file => file.id == id);
            if(uploadedFileIndex > -1){
                state.uploadedFiles.splice(uploadedFileIndex, 1);
            }
        }
    },

    actions: {
        addFile(context: UploaderContext, file: UploadedFiles) {
            context.commit('addFile', file)
        },

        updateFile(context: UploaderContext, payload: UploadedFiles) {
            context.commit('updateFile', payload)
        },

        removeFile(context: UploaderContext, id: string) {
            context.commit('removeFile', id)
        }
    }
}

const { read, dispatch } = getStoreAccessors<UploaderState, ApplicationState>('uploader');

const getters = uploaderState.getters;
const actions = uploaderState.actions;

export const getUploadedFiles = read(getters.getUploadedFiles);

export const addFile = dispatch(actions.addFile);
export const removeFile = dispatch(actions.removeFile);
export const updateFile = dispatch(actions.updateFile);