







import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class HorizontalLoader extends Vue {
  @Prop({ default: "Loading..." }) loadingMessage!: string;
  @Prop({ default: true }) showMessage!: boolean;
}
