















































import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';
import {Vue, Component} from 'vue-property-decorator';

@Component
export default class Email extends Vue {
    get projectData() {
      return getProjectData(this.$store);
    }

    proceed() {
        console.log('object');
        this.$emit('done')
    }

    get ssid() {
      return this.projectData.projectData.content.ssid;
    }

    set ssid(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            ssid: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get networkType() {
      return this.projectData.projectData.content.networktype;
    }

    setNetworkType(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            networktype: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get password() {
      return this.projectData.projectData.content.wifipass;
    }

    set password(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            wifipass: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }
}
