











































































































import { Vue, Component } from "vue-property-decorator";
import { currencies } from "./utils";
import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from "@/utils/eventBus";

@Component
export default class Paypal extends Vue {
    get currencies() {
        return currencies();
    }

    get projectData() {
      return getProjectData(this.$store);
    }

    get ppEmail() {
      return this.projectData.projectData.content.pp_email;
    }

    set ppEmail(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            pp_email: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get ppName() {
      return this.projectData.projectData.content.pp_name;
    }

    set ppName(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            pp_name: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get ppId() {
      return this.projectData.projectData.content.pp_id;
    }

    set ppId(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            pp_id: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get ppPrice() {
      return this.projectData.projectData.content.pp_price;
    }

    set ppPrice(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            pp_price: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get ppCurrency() {
      return this.projectData.projectData.content.pp_currency;
    }

    setCurrency(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            pp_currency: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get ppShipping() {
      return this.projectData.projectData.content.pp_shipping;
    }

    set ppShipping(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            pp_shipping: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }
    
    get ppTax() {
      return this.projectData.projectData.content.pp_tax;
    }

    set ppTax(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            pp_tax: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get ppType() {
      const pp = this.projectData.projectData.content.pp_type;
      if(pp == '_xclick') return 'Buy Now';
      if(pp == '_cart') return 'Add to cart';
      if(pp == '_donations') return 'Donations';
      return '';
    }

    setPpType(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            pp_type: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    proceed() {

        this.$emit('done')
    }
}
