























import { getProjectData } from '@/store/editorState';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Preview extends Vue {
    get projectData() {
        return getProjectData(this.$store);
    }

    get pageColor() {
        return {
            backgroundColor: this.appQrContent.pageColor
        }
    }

    get appQrContent() {
        return this.projectData.projectData.content;
    }

    get appName() {
        return this.appQrContent.name;
    }

    get appDescription() {
        return this.appQrContent.description;
    }

    get appSite() {
        return this.appQrContent.website;
    }

    get appLogo() {
        return this.appQrContent.logo
    }

    get textStyle() {
        return {
            fontFamily: this.appQrContent.titleFont,
            fontSize: `${this.appQrContent.titleSize}px`,
            color: `${this.appQrContent.titleColor} !important`
        }
    }
}
