

































































import { getProjectData, updateProjectData } from '@/store/editorState';
import {Vue, Component} from 'vue-property-decorator';
import ColorPicker from '@/components/colorPicker.vue';
import ImageUploader from '@/components/imageUploader.vue';
import { generateId } from '@/utils/utils';

@Component({ components: { ColorPicker, ImageUploader } })
export default class AudioQrSettings extends Vue {

    proceed() {
        this.$emit('done')
    }

    get uploaderKey() {
        return generateId();
    }

     get projectData() {
        return getProjectData(this.$store);
    }

    get audioQrContent() {
        return this.projectData.projectData.content;
    }

      get titleFont(): string {
        return this.audioQrContent.titleFont
    }

     set titleFont(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.audioQrContent,
                    titleFont: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleColor(): string {
        return this.audioQrContent.titleColor;
    }

    set titleColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.audioQrContent,
                    titleColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleSize(): string {
        return this.audioQrContent.titleSize;
    }

    set titleSize(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.audioQrContent,
                    titleSize: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }
    
    get pageColor(): string {
        return this.audioQrContent.pageColor;
    }

    set pageColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.audioQrContent,
                    pageColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get title(): string {
        return this.audioQrContent.title;
    }

    set title(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.audioQrContent,
                    title: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get audioFiles() {
        return this.audioQrContent.audioFiles;
    }

    handleDone(done: any) {
        const audioFiles = [
            ...this.audioFiles,
            done
        ]

        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.audioQrContent,
                    audioFiles
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    removeAudio(file: any) {
        const audioIndex = this.audioFiles.findIndex((audio: any) => {
            return audio.id == file.id
        });

        if(audioIndex > -1) {
            const filesBuffer = this.audioFiles;
            filesBuffer.splice(audioIndex, 1);

             const payload = {
            ...this.projectData,
                projectData: {
                    ...this.projectData.projectData,
                    content: {
                        ...this.audioQrContent,
                        audioFiles: filesBuffer
                    }
                }
            }


            updateProjectData(this.$store, payload);
        }
    }
}
