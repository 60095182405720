

































import {Vue, Component, Prop} from 'vue-property-decorator';
import { fromNow } from '@/utils/dayjs';
import { pinJob } from '@/store/finderState';

@Component
export default class JobCard extends Vue {
    @Prop({ default: () => ({}) }) job!: any;

    timeAgo() {
        const date = new Date(this.job.time_submitted * 1000);
        return fromNow(date.toString())
    }

    get price() {
        const exchangeRate = this.job.currency.exchange_rate;
        const minimum = this.job.budget.minimum;
        const maximum = this.job.budget.maximum;

        const exchangedMinimum = Math.round(minimum * exchangeRate);
        const exchangedMaximum = Math.round(maximum * exchangeRate);

        return `${exchangedMinimum} - ${exchangedMaximum} USD`

    }

    pinJob() {
        this.$toast.info('Pinning Job...')
        pinJob(this.$store, this.job);
    }

    viewJob() {
        const url = `https://www.freelancer.com/projects/${this.job.seo_url}`;
        window.open(url, '_blank')
    }

}
