export type Step = {
    name: string;
    description?: string;
}

export enum StepState {
    STALE = 'stale',
    ACTIVE = 'active',
    FINISHED = 'finished',
    DONE = 'done'
}