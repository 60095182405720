











import { Component, Vue } from 'vue-property-decorator';
import AppQrSettings from './settings.vue';
import Preview from './preview.vue';

@Component({ components: { AppQrSettings, Preview } })
export default class AppQr extends Vue {
    done() {
       this.$emit('done')
    }
}
