











import {Vue, Component} from 'vue-property-decorator';
import ImagesSettings from './settings.vue';
import Preview from './preview.vue';

@Component({ components: { ImagesSettings, Preview } })
export default class ImageQr extends Vue {
    done() {
      this.$emit('done');
    }
}
