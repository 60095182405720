


























import { getProjectData } from '@/store/editorState';
import { Component, Vue } from 'vue-property-decorator';
import { socialMediaColors } from './socialMedia';

@Component
export default class Preview extends Vue {
    get projectData() {
        return getProjectData(this.$store);
    }

    get pageColor() {
        return {
            backgroundColor: this.socialMediaContent.pageColor
        }
    }

    get socialMediaContent() {
        return this.projectData.projectData.content;
    }

    get socials() {
        return this.socialMediaContent.socials;
    }

    get site() {
        return this.socialMediaContent.website;
    }

    socialColor(social: any) {
        return {
            color: socialMediaColors[social]
        }
    }

}
