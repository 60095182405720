












































































































































































import { Vue, Component } from "vue-property-decorator";
import ColorPicker from "@/components/colorPicker.vue";
import { getProjectData, updateProjectData } from "@/store/editorState";
import $ from "jquery";
import { generateId } from "@/utils/utils";
import ImageUploader from "@/components/imageUploader.vue";
import { formatDate, dynamicFormat } from "@/utils/dayjs";
import Datetime from "vue-datetime";

@Component({ components: { ColorPicker, ImageUploader } })
export default class CouponSettings extends Vue {
  proceed() {
    this.$emit("done");
  }

  mounted() {
    ($(".datepicker") as any).datepicker({
        startDate: new Date().toString(),
    }).on("changeDate", (e: any) => {
      e.stopPropagation();
      if (e.currentTarget.name == "startDate") {
        this.startDate = e.date.toString();
      }

      if (e.currentTarget.name == "expiryDate") {
        this.expiryDate = e.date.toString();
      }
    });
  }

  get uploaderKey() {
    return generateId();
  }

  get projectData() {
    return getProjectData(this.$store);
  }

  get couponContent() {
    return this.projectData.projectData.content;
  }

  get pageColor(): string {
    return this.couponContent.pageColor;
  }

  set pageColor(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          pageColor: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get logo(): string {
    return this.couponContent.logo;
  }

  set logo(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          logo: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get title(): string {
    return this.couponContent.title;
  }

  set title(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          title: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get discount(): string {
    return this.couponContent.discount;
  }

  set discount(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          discount: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get startDate(): string {
    return formatDate(this.couponContent.startDate);
  }

  set startDate(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          startDate: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get expiryDate(): string {
    return formatDate(this.couponContent.expiryDate);
  }

  set expiryDate(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          expiryDate: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get description(): string {
    return this.couponContent.description;
  }

  set description(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          description: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get term(): string {
    return this.couponContent.term;
  }

  set term(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          term: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get code(): string {
    return this.couponContent.code;
  }

  set code(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          code: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get titleFont(): string {
    return this.couponContent.titleFont;
  }

  set titleFont(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          titleFont: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get titleColor(): string {
    return this.couponContent.titleColor;
  }

  set titleColor(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          titleColor: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get titleSize(): string {
    return this.couponContent.titleSize;
  }

  set titleSize(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          titleSize: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }

  get dateFormat(): string {
    return this.couponContent.dateFormat;
  }

  set dateFormat(value: string) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.couponContent,
          dateFormat: value,
        },
      },
    };

    updateProjectData(this.$store, payload);
  }
}
