export const pattern = [
    'default', 
    'circle',
    'diamond',
    'star',
    'sparkle',
    'heart',
    'ellipse',
    'special-circle-vert',
    'ribbon',
    'special-circle-orizz'
]

export const markerBorder = [
    'default',
    // 'flurry',
    'sdoz',
    'drop_in',
    'drop',
    'dropeye',
    'dropeyeleft',
    'dropeyeleaf',
    'dropeyeright',
    'squarecircle',
    'circle',
    'vortex'
];

export const markerCenter = [
    'default',
    // 'flurry',
    'sdoz',
    'drop_in',
    'drop',
    'dropeye',
    'circle',
    'rounded',
    '9-dots',
    '9-dots-fat',
    'bruised',
    'ropes',
    'diagonal',
    'elastic',
    'sun',
    'heart',
    'x',
    'vortex',
    'sparkle_dot'
];

export const frames = [
    'top',
    'bottom',
    'ribbon-top',
    'ribbon-bottom',
    'balloon-top',
    'balloon-bottom',
    'cine',
    'phone',
]

export const logos = [
    '02-email',
    '03-location-b',
    '03-location',
    '04-whatsapp',
    '05-skype',
    '06-zoom',
    '10-paypal'
]