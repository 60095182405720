import { dynamicPageConfig } from "@/views/editor/projectData";

export type audioQrConfig = dynamicPageConfig & {
    title: string
    audioFiles: {
        name: string,
        audioUrl: string
    }[],
    titleColor: string,
    titleFont: string,
    titleSize: string
}

export const audioQrDefaults: audioQrConfig = {
    title: 'Select an audio',
    pageColor: '#027bff',
    audioFiles: [],
    titleColor: '#fff',
    titleFont: '',
    titleSize: '24',
}