export type stepConfig = {
    name: string,
    showStepper: boolean,
    componentName: string;
    validator?: () => boolean;
    validationErrorMessage: string
}

export const editorSteps: stepConfig[] = [
    {
        name: 'Choose QR Category',
        showStepper: true,
        componentName: 'choose-category',
        validator: () => {
            return true;
        },
        validationErrorMessage: 'Choose QR Type'
    },

    {
        name: 'Enter Content',
        showStepper: true,
        componentName: 'enter-content',
        validator: () => {
            return true
        },
        validationErrorMessage: 'You cannot merge only one audio file.'
    },

    {
        name: 'Customize',
        showStepper: true,
        componentName: 'customize',
        validator: () => {
            return true
        },
        validationErrorMessage: 'You cannot merge only one audio file.'
    },

    {
        name: 'Create QR Code',
        showStepper: true,
        componentName: 'download-qr-code',
        validator: () => {
            return true
        },
        validationErrorMessage: 'You cannot merge only one audio file.'
    }
];