































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDateTimePPTZ } from '@/utils/dayjs'
import lookUp from 'country-code-lookup';
import ApexCharts from 'apexcharts';
import { processStat } from './analyticsCruncher'

@Component
export default class ScanInfoModal extends Vue {
    @Prop({ default: '' }) id!: string;
    @Prop({ default: () => ({}) }) project!: any;

    get scanTimes() {
        return this.project.scanTimes + this.project.scanInfo.length
    }

    get scanInfo() {
        return this.project.scanInfo
    }

    get processedSeries() {
        return processStat(this.project.scanInfo)
    }

    formatTime(scanInfo: any) {
        return formatDateTimePPTZ(scanInfo.createdAt);
    }

    getLookUp(countryCode: string) {
        const country = lookUp.byIso(countryCode.toLowerCase());
        if (country != null) {
            return `${country.country}`
        }

        return countryCode;
    }

    generateChart() {
        const options = {
            chart: {
                fontFamily: "Quicksand, sans-serif",
                height: 380,
                width: "100%",
                type: "area",
                animations: {
                    enabled: true,
                    easing: "easeinout",
                    speed: 800
                },
                toolbar: {
                    show: false
                }
            },
            colors: ["#DF633C"],
            series: [
                {
                    name: "Visits",
                    data: this.processedSeries
                }
            ],
            xaxis: {
                type: "datetime",
                title: {
                    text: "Time"
                }
            },
            yaxis: {
                type: 'numeric',
                crosshairs: {
                    show: false
                },
                title: {
                    text: "Count"
                }
            }
        };
        const chart = new ApexCharts(document.querySelector(`#apex-${this.project.id}`), options);
        chart.render();
    }

    mounted() {
        setTimeout(() => {
            if (this.project.scanInfo.length > 0) {
                this.generateChart()
            }
        }, 1000)
    }
}


