





























import { getProjectData } from '@/store/editorState';
import { dynamicFormat, formatDate } from '@/utils/dayjs';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Preview extends Vue {
     get projectData() {
        return getProjectData(this.$store);
    }

    get couponContent() {
        return this.projectData.projectData.content;
    }

    get pageColor() {
        return {
            backgroundColor: this.couponContent.pageColor
        }
    }

    get logo() {
        return this.couponContent.logo
    }

    get title() {
        return this.couponContent.title
    }

    get description() {
        return this.couponContent.description
    }

    get effectiveDate() {
        return dynamicFormat(this.couponContent.expiryDate, this.couponContent.dateFormat);
    }

    get term() {
        return this.couponContent.term
    }

     get textStyle() {
        return {
            fontFamily: this.couponContent.titleFont,
            fontSize: `${this.couponContent.titleSize}px`,
            color: `${this.couponContent.titleColor} !important`
        }
    }

}

