











import {Vue, Component} from 'vue-property-decorator';
import FeedbackSettings from './settings.vue';
import Preview from './preview.vue';

@Component({ components: { FeedbackSettings, Preview } })
export default class Feedback extends Vue {
    done () {
        this.$emit('done');
    }
}
