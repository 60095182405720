import { dynamicPageConfig } from '@/views/editor/projectData';

type product = {
    image: string
    title: string;
    description: string;
    price: string;
}

type section = {
    name: string;
    description: string;
    product: product[]
}

export type menuConfig = dynamicPageConfig & {
    companyLogo: string;
    titleColor: string;
    titleFont: string;
    titleSize: string;
    establishment: string;
    description: string;
    sections: section[]
}

export const menuDefaults: menuConfig = {
    pageColor: '#027bff',
    companyLogo: '',
    titleColor: '#fff',
    titleFont: '',
    titleSize: '24',
    establishment: '',
    description: '',
    sections: []
}