import { ApplicationState } from "./applicationState";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { initServer, SERVER } from "@/utils/server";
type ProjectContext = ActionContext<ProjectState, ApplicationState>

export interface ProjectState {
    projects: any[],
    quotaLeft: any,
}

const initialState: ProjectState = {
    projects: [],
    quotaLeft: ''
}

export const projectState = {	
     namespaced: true, 
     state: initialState,
     getters: {
        getProjects(state: ProjectState) {
            return state.projects
        },

        getQuota(state: ProjectState) {
            return state.quotaLeft
        }
     },
     mutations: {
         setProjects(state: ProjectState, projects: any) {
             state.projects = projects;
         },

         deleteProject(state: ProjectState, projectId: string) {
             const projectIndex = state.projects.findIndex((project) => project.id == projectId);
             if(projectIndex > -1) {
                 state.projects.splice(projectIndex, 1);
             }
         },

         setQuota(state: ProjectState, quota: any){
             state.quotaLeft = quota;
         }
     },
     actions: {
         fetchProjects(context: ProjectContext){
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.get('projects')
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success) {
                        context.commit('setProjects', apiResponse.data.result)
                        context.commit('setQuota', apiResponse.data.quotaLeft)
                    }
                }).catch((err) => {
                    reject(err)
                })
            });
         },

         deleteProject(context: ProjectContext, projectId: string) {
             return new Promise<void>((resolve, reject) => {
                 initServer(SERVER)
                 const request = SERVER.delete(`projects/${projectId}`)
                 
                 request.then((response) => {
                     const apiResponse= response.data;
                     if(apiResponse.success) {
                         context.commit('deleteProject', projectId);
                         resolve();
                     } else {
                         reject()
                     }
                 }).catch((err) => {
                     reject(err)
                 })
             })
         }
     },
}

const { read, dispatch } = getStoreAccessors<ProjectState, ApplicationState>('project');

const getters = projectState.getters;
const actions = projectState.actions;

export const getProjects = read(getters.getProjects);
export const getQuota = read(getters.getQuota);

export const fetchProjects = dispatch(actions.fetchProjects);
export const deleteProject = dispatch(actions.deleteProject);