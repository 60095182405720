import { dynamicPageConfig } from "@/views/editor/projectData";

export type couponConfig  = dynamicPageConfig & {
    logo: string,
    title: string,
    description: string,
    term: string,
    code: string,
    discount: string,
    expiryDate: string,
    startDate: string,
    titleColor: string,
    titleFont: string,
    titleSize: string,
    dateFormat: string
}

export const couponDefaults: couponConfig = {
    pageColor: '#027bff',
    logo: '',
    title: '',
    code: '',
    discount: '',
    expiryDate: '',
    description: '',
    term: '',
    startDate: '', 
    titleColor: '#fff',
    titleFont: '',
    titleSize: '24',
    dateFormat: 'MM/DD/YYYY'
}
