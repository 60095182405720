import { ApplicationState } from "./applicationState";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { initServer, SERVER } from "@/utils/server";
import { v4 as uuid } from 'uuid';

type WhiteLabelContext = ActionContext<WhiteLabelState, ApplicationState>

export interface WhiteLabelState {
    whitelabel: any;
    whitelabelUsers: any[]
}

const initialState: WhiteLabelState = {
    whitelabel: {
        id: uuid(),
        hostName: '',
        siteName: '',
        siteColor: '#00a4ff',
        logoUrl: ''
    },
    whitelabelUsers: []
}

export const whitelabelState = {	
     namespaced: true, 
     state: initialState,
     getters: {
         getWhitelabel(state: WhiteLabelState) {
            return state.whitelabel;
         },

         getWhitelabelUsers(state: WhiteLabelState) {
            return state.whitelabelUsers
         }
     },
     mutations: {
         updateWhitelabel(state: WhiteLabelState, whitelabel: any) {
             state.whitelabel = { ...state.whitelabel, ...whitelabel };
         },

         setWhitelabelUsers(state: WhiteLabelState, users: any[]) {
            state.whitelabelUsers = users;
         },

         appendUser(state: WhiteLabelState, user: any) {
             state.whitelabelUsers.push(user);
         },

         deleteUser(state: WhiteLabelState, userId: string) {
            const userIndex = state.whitelabelUsers.findIndex(user => user.id == userId);
            if(userIndex > -1){
                state.whitelabelUsers.splice(userIndex, 1);
            }
         },

         updateUser(state: WhiteLabelState, user: any) {
            const userIndex = state.whitelabelUsers.findIndex(usr => user.id == usr.id);
            if(userIndex > -1){
                state.whitelabelUsers.splice(userIndex, 1, user);
            }
         }
     },
     actions: {
        fetchWhitelabel(context: WhiteLabelContext) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.get('wl')
                
                request.then((response) => {
                    const apiResponse = response.data;
                    if(apiResponse.data != null){
                        context.commit('updateWhitelabel', apiResponse.data);
                        resolve('done');
                    }
                }).catch((err) => {
                    reject(err)
                })
            });
        },

        fetchWhitelabelUsers(context: WhiteLabelContext, whitelabelId: string) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.get(`wl/users/${whitelabelId}`);
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        context.commit('setWhitelabelUsers', apiResponse.data);
                        resolve('done');
                    }
                    
                }).catch((err) => {
                    reject(err)
                })
            });
        },

        saveWhitelabel(context: WhiteLabelContext) {
            return new Promise((resolve, reject) => {
                const postParams = context.state.whitelabel;
                initServer(SERVER)
                const request = SERVER.post('wl/create', postParams)
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        resolve('done');
                    } else {
                        reject(apiResponse.message)
                    }
                    resolve('done')
                }).catch((err) => {
                    reject(err)
                })
            });
        },

        createWhitelabelUser(context: WhiteLabelContext, payload: any) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.post('wl/user', payload)
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        context.commit('appendUser', apiResponse.data);
                        resolve('done')
                    } else {
                        reject(apiResponse.message)
                    }
                }).catch((err) => {
                    reject(err)
                })
            });
        },

        updateWhitelabel(context: WhiteLabelContext, whitelabel: any) {
            context.commit('updateWhitelabel', whitelabel)
        },

        updateWhitelabelUser(context: WhiteLabelContext, user: any) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.put('/wl/user', user)
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success) {
                        context.commit('updateUser', apiResponse.data);
                        resolve('done')
                    } else {
                        reject(apiResponse.message)
                    }
                    
                }).catch((err) => {
                    reject(err)
                })
            });
        },

        deleteWhitelabelUser(context: WhiteLabelContext, payload: any) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.post('/wl/user/delete', { id: payload.id, whiteLabelId: context.state.whitelabel.id })
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        context.commit('deleteUser', payload.id)
                        resolve('done')
                    } else {
                        reject(apiResponse.message)
                    }
                }).catch((err) => {
                    reject(err)
                })
            });
        }
     },
}

const { read, dispatch } = getStoreAccessors<WhiteLabelState, ApplicationState>('whitelabel');

const getters = whitelabelState.getters;
const actions = whitelabelState.actions;

export const getWhitelabel = read(getters.getWhitelabel);
export const getWhitelabelUsers = read(getters.getWhitelabelUsers);

export const fetchWhitelabel = dispatch(actions.fetchWhitelabel);
export const saveWhitelabel = dispatch(actions.saveWhitelabel);
export const updateWhitelabel = dispatch(actions.updateWhitelabel);
export const fetchWhitelabelUsers = dispatch(actions.fetchWhitelabelUsers);
export const createWhitelabelUser = dispatch(actions.createWhitelabelUser);
export const deleteUser = dispatch(actions.deleteWhitelabelUser);
export const updateWhitelabelUser = dispatch(actions.updateWhitelabelUser);