
















import { unpinJob } from '@/store/finderState';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PinnedJobCards extends Vue {
    @Prop({ default: () => ({}) }) job!: any;

    get price() {
        const exchangeRate = this.job.jobData.currency.exchange_rate;
        const minimum = this.job.jobData.budget.minimum;
        const maximum = this.job.jobData.budget.maximum;

        const exchangedMinimum = Math.round(minimum * exchangeRate);
        const exchangedMaximum = Math.round(maximum * exchangeRate);

        return `${exchangedMinimum} - ${exchangedMaximum} USD`

    }

    viewJob() {
        const url = `https://www.freelancer.com/projects/${this.job.jobData.seo_url}`;
        window.open(url, '_blank')
    }

    unpinJob() {
        this.$toast.info('Unpinning Job...');
        unpinJob(this.$store, this.job.id).then(() => {
            this.$toast.success('Job Unpinned');
        });
    }

}
