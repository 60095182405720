
































import { Vue, Component } from "vue-property-decorator";
import { categories, dynamicCategoryContentDefaults } from "./category";
import CategoryCard from "./categoryCard.vue";
import { getProjectData, updateProjectData } from "@/store/editorState";
import { dynamicScanUrl } from "@/utils/server";

@Component({ components: { CategoryCard } })
export default class ChooseCategory extends Vue {
  selectedType = "all";

  get categories() {
    if(this.selectedType == 'all'){
        return categories;
    } else {
        return categories.filter(category => {
            return category.type.includes(this.selectedType);
        })
    }
  }

  get projectData() {
    return getProjectData(this.$store);
  }

  handleSelected(category: any) {
    const payload = {
      ...this.projectData,
      projectData: {
        ...this.projectData.projectData,
        content: {
          ...this.projectData.projectData.content,
          ...this.dynamicQrCotentDefaults(category),
          link: category.type == 'static' ? this.projectData.projectData.content.websiteUrl : this.getDynamicUrl(),
          section: category.section,
          type: category.type,
                    
        }
      },
      category: category.slug
    };

    console.log(payload);

    updateProjectData(this.$store, payload);
    window.scrollTo(0,0);
    this.$emit("done");
  }

  getDynamicUrl() {
      return `${dynamicScanUrl}/${this.projectData.scanCode}`
   }

  setType(type: string) {
    this.selectedType = type;
  }

  dynamicQrCotentDefaults(category: any) {
    const content = dynamicCategoryContentDefaults[category.slug];
    return content ? content : {}
  }

  isActiveType(type: string) {
    return this.selectedType == type;
  }
}
