































import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';
import {Vue, Component} from 'vue-property-decorator';

@Component
export default class Email extends Vue {

    get projectData() {
        return getProjectData(this.$store);
    }

    get mailto() {
        return this.projectData.projectData.content.mailto;
    }

    set mailto(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    mailto: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get subject() {
        return this.projectData.projectData.content.subject;
    }

    set subject(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    subject: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get body() {
        return this.projectData.projectData.content.body;
    }

    set body(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    body: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }
    
    proceed() {
        if(this.projectData.projectData.content.mailto.length == 0){
            this.$toast.error('Enter Recepient');
            return;
        }

         if(this.projectData.projectData.content.subject.length == 0){
            this.$toast.error('Enter subject');
            return;
        }

         if(this.projectData.projectData.content.body.length == 0){
            this.$toast.error('Enter Body');
            return;
        }

        this.$emit('done')
    }
}
