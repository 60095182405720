














































import { getProjectData, updateProjectData } from "@/store/editorState";
import { daddyEventBus, daddyEvents } from "@/utils/eventBus";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Bitcoin extends Vue {
  get projectData() {
      return getProjectData(this.$store);
    }
    
    get btcAccount() {
      return this.projectData.projectData.content.btc_account;
    }

    set btcAccount(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            btc_account: value
          }
        }
      }

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get btcAmount() {
      return this.projectData.projectData.content.btc_amount;
    }

    set btcAmount(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            btc_amount: value
          }
        }
      }
      

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get btcLabel() {
      return this.projectData.projectData.content.btc_label;
    }

    set btcLabel(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            btc_label: value
          }
        }
      }
      

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get btcMessage() {
      return this.projectData.projectData.content.btc_message;
    }

    set btcMessage(value: string) {
      const payload = {
        ...this.projectData,
        projectData: {
          ...this.projectData.projectData,
          content: {
            ...this.projectData.projectData.content,
            btc_message: value
          }
        }
      }
      

      updateProjectData(this.$store, payload);
      daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    proceed() {

        this.$emit('done')
    }
}
