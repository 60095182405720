import { ApplicationState } from "./applicationState";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { initServer, SERVER } from "@/utils/server";
type FolderContext = ActionContext<FolderState, ApplicationState>

export interface FolderState {
    folders: any[],
    quota: any
}

const initialState: FolderState = {
    folders: [],
    quota: ''
}

export const folderState = {	
     namespaced: true, 
     state: initialState,
     getters: {
        getFolders(state: FolderState) {
            return state.folders;
        },

        getQuota(state: FolderState) {
            return state.quota;
        }
     },
     mutations: {
        setFolders(state: FolderState, folders: any[]){
            state.folders = folders;
        },

        appendFolder(state: FolderState, folder: any) {
            state.folders.push(folder)
        },

        setQuota(state: FolderState, quota: any) {
            state.quota = quota;
        }
     },
     actions: {
        fetchFolders(context: FolderContext) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.get('folders')
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        //commit
                        context.commit('setFolders', apiResponse.data.folders)
                        context.commit('setQuota', apiResponse.data.quota)
                        resolve('done')
                    }
                }).catch((err) => {
                    reject(err)
                })
            });
        },

        createFolder(context: FolderContext, payload: any) {
            return new Promise((resolve, reject) => {
                initServer(SERVER)
                const request = SERVER.post('folders', payload)
                
                request.then((response) => {
                    const apiResponse= response.data;
                    if(apiResponse.success){
                        context.commit('appendFolder', apiResponse.data.folder)
                        context.commit('setQuota', apiResponse.data.quota)
                        resolve(apiResponse.message)
                    } else {
                        reject(apiResponse.message);
                    }
                }).catch((err) => {
                    reject(err)
                })
            });
        }
     },
}

const { read, dispatch } = getStoreAccessors<FolderState, ApplicationState>('folder');

const getters = folderState.getters;
const actions = folderState.actions;

export const getFolders = read(getters.getFolders);
export const getQuota = read(getters.getQuota);

export const fetchFolders = dispatch(actions.fetchFolders);
export const createFolder = dispatch(actions.createFolder);