











import {Vue, Component} from 'vue-property-decorator';
import VideosSettings from './settings.vue';
import Preview from './preview.vue';

@Component({ components: { VideosSettings, Preview } })
export default class VideoQr extends Vue {
    done() {
        this.$emit('done');
    }
}
