




















































































































import { Vue, Component } from 'vue-property-decorator';
import ColorPicker from '@/components/colorPicker.vue';
import { getProjectData, updateProjectData } from '@/store/editorState';
import ImageUploader from '@/components/imageUploader.vue';
import { generateId } from '@/utils/utils';

@Component({ components: { ColorPicker, ImageUploader } })
export default class Settings extends Vue {

    companyLogoUploaderKey = generateId();

    proceed() {
       this.$emit('done')
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get menuQrContent() {
        return this.projectData.projectData.content;
    }

    get pageColor(): string {
        return this.menuQrContent.pageColor;
    }

    set pageColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.menuQrContent,
                    pageColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get companyLogo(): string {
        return this.menuQrContent.companyLogo;
    }

    set companyLogo(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.menuQrContent,
                    companyLogo: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get establishment() {
        return this.menuQrContent.establishment
    }

    set establishment(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.menuQrContent,
                    establishment: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get description() {
        return this.menuQrContent.description
    }

    set description(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.menuQrContent,
                    description: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleFont(): string {
        return this.menuQrContent.titleFont
    }

    set titleFont(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.menuQrContent,
                    titleFont: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleColor(): string {
        return this.menuQrContent.titleColor;
    }

    set titleColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.menuQrContent,
                    titleColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleSize(): string {
        return this.menuQrContent.titleSize;
    }

    set titleSize(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.menuQrContent,
                    titleSize: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get sections() {
        return this.menuQrContent.sections;
    }

    addSection() {
        const newSection = {
            title: '',
            description: '',
            product: []
        }

        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.menuQrContent,
                    sections: [
                        ...this.menuQrContent.sections,
                        newSection
                    ]
                }
            }
        }

        updateProjectData(this.$store, payload);

    }

    removeSection(removeIndex: number) {
        const newSection = this.menuQrContent.sections.filter((_: any, index: number) => index !== removeIndex);
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.menuQrContent,
                    sections: newSection
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    addProduct(sectionIndex: number) {
        const section = this.menuQrContent.sections.filter((_: any, index: number) => index == sectionIndex)[0];
        const editedSection = {
            ...section,
            product: [
                ...section.product,
                {
                    title: '',
                    image: '',
                    description: '',
                    price: ''
                }
            ]
        }

        this.menuQrContent.sections.splice(sectionIndex, 1, editedSection)
    }

    removeProduct(sectionIndex: number, productIndex: number) {
        const section = this.menuQrContent.sections.filter((_: any, index: number) => index == sectionIndex)[0];
        section.product.splice(productIndex, 1);
    }

}
