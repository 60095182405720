



































import { getProjectData } from '@/store/editorState';
import { Vue, Component } from 'vue-property-decorator';
import { StarRating } from 'vue-rate-it'

@Component({ components: { StarRating } })
export default class Preview extends Vue {
     get projectData() {
        return getProjectData(this.$store);
    }

    get feedbackContent() {
        return this.projectData.projectData.content;
    }

    get pageColor() {
        return {
            backgroundColor: this.feedbackContent.pageColor
        }
    }

    get title() {
        return this.feedbackContent.title;
    }

    get logo() {
        return this.feedbackContent.logo;
    }

     get textStyle() {
        return {
            fontFamily: this.feedbackContent.titleFont,
            fontSize: `${this.feedbackContent.titleSize}px`,
            color: `${this.feedbackContent.titleColor} !important`
        }
    }
}
