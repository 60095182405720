








































































import { getProjectData } from '@/store/editorState';
import { Component, Vue } from 'vue-property-decorator';
import { socialMediaColors } from '../social-media/socialMedia';

@Component
export default class Preview extends Vue {
    get projectData() {
        return getProjectData(this.$store);
    }

    get pageColor() {
        return {
            backgroundColor: this.profileContent.pageColor
        }
    }

    get buttonColor() {
        return {
            backgroundColor: this.profileContent.buttonColor
        }
    }

    get profileContent() {
        return this.projectData.projectData.content;
    }

    get fullName() {
        return this.profileContent.fullName;
    }

    get job() {
        return this.profileContent.job;
    }

    get phone () {
        return this.profileContent.phone;
    }

    get email () {
        return this.profileContent.email;
    }

    get company() {
        return this.profileContent.company;
    }

    get logo() {
        return this.profileContent.logo
    }

    get socials() {
        return this.profileContent.socials;
    }

    get site() {
        return this.profileContent.website;
    }

    get address() {
        return this.profileContent.address;
    }

    get city() {
        return this.profileContent.city;
    }

    get state() {
        return this.profileContent.lState;
    }

    get country() {
        return this.profileContent.country;
    }

    socialColor(social: any) {
        return {
            color: socialMediaColors[social]
        }
    }

     get textStyle() {
        return {
            fontFamily: this.profileContent.titleFont,
            fontSize: `${this.profileContent.titleSize}px`,
            color: `${this.profileContent.titleColor} !important`
        }
    }
}
