




























































import { getProjectData, updateProjectData } from '@/store/editorState';
import { generateId } from '@/utils/utils';
import { Vue, Component } from 'vue-property-decorator';
import ColorPicker from '@/components/colorPicker.vue';

@Component({ components: { ColorPicker } })
export default class Settings extends Vue {
    proceed() {
       this.$emit('done')
    }

    get uploaderKey() {
      return generateId();
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get videoContent() {
        return this.projectData.projectData.content
    }

      get titleFont(): string {
        return this.videoContent.titleFont
    }

     set titleFont(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.videoContent,
                    titleFont: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleColor(): string {
        return this.videoContent.titleColor;
    }

    set titleColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.videoContent,
                    titleColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleSize(): string {
        return this.videoContent.titleSize;
    }

    set titleSize(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.videoContent,
                    titleSize: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get pageColor(): string {
        return this.videoContent.pageColor;
    }

    set pageColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.videoContent,
                    pageColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get title() {
        return this.videoContent.title;
    }

    set title(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.videoContent,
                    title: value
                }
            }
        }

        updateProjectData(this.$store, payload); 
    }

    get description() {
        return this.videoContent.description;
    }

    set description(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.videoContent,
                    description: value
                }
            }
        }

        updateProjectData(this.$store, payload); 
    }

    get videoLink() {
        return this.videoContent.videoLink;
    }

    set videoLink(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.videoContent,
                    videoLink: value
                }
            }
        }

        updateProjectData(this.$store, payload); 
    }
}
