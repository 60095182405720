











import { Component, Vue } from 'vue-property-decorator';
import WeddingSettings from './settings.vue';
import Preview from './preview.vue';

@Component({ components: { WeddingSettings, Preview } })
export default class Wedding extends Vue {
    
}
