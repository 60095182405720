










import {Vue, Component} from 'vue-property-decorator';
import { getUploadedFiles } from '@/store/uploaderState';
import ProgressBar from './progressBar.vue';

@Component({ components: { ProgressBar } })
export default class ProgressLoadingComponent extends Vue {
  get totalProgress() {
    const allProgress = this.uploadedFiles.map((file: any) => file.progress);
    const sum = allProgress.reduce((a: number, b: number) => a + b,0);
    const avg = (sum / allProgress.length) || 0;
    return Math.round(avg);
  }

  get uploadedFiles(){
    return getUploadedFiles(this.$store)
  }
}
