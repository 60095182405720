
































import { deleteProject } from '@/store/projectState';
import { Modal } from 'bootstrap';
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class ConfirmDeleteModal extends Vue {
    @Prop({ default: '' }) id!: string;
    @Prop({ default: '' }) name!: string;

    deleteProject() {
        this.cancel();
        this.$toast.info(`Deleting ${this.name}`);

        deleteProject(this.$store, this.id).then(() => {
            this.$toast.success('Deleted Successfully')
        }).catch(() => {
            this.$toast.error('Could not delete project')
        })
    }

    cancel() {
        Modal.getInstance(document.getElementById(this.id)!)!.hide();
    }
}
