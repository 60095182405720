
















import {Vue, Component} from 'vue-property-decorator';
import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';

@Component
export default class Email extends Vue {
    get projectData() {
        return getProjectData(this.$store);
    }

     get textContent() {
        return this.projectData.projectData.content.data;
    }

    set textContent(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    data: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    proceed() {
        if(this.projectData.projectData.content.data.length == 0){
            this.$toast.error('Enter Content');
            return;
        }

        this.$emit('done')
    }
}
