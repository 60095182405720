

















import { Vue, Component } from 'vue-property-decorator';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';
import { updateProjectData, getProjectData, fetchQrCodePreview, setCodePreview } from '@/store/editorState';
import { customizationDefaults } from '../../projectData';
import HorizontalLoader from '@/components/loader.vue';

@Component({ components: { HorizontalLoader } })
export default class QrPreview extends Vue {
    svgContent = '';
    isGeneratingPreview = false;
    mounted(){
        this.updateQr();
        daddyEventBus.$on(daddyEvents.CustomizationEvent, this.updateQr)
    }

    destroy(){
        daddyEventBus.$off(daddyEvents.CustomizationEvent, this.updateQr);
    }

    updateQr(){
        this.isGeneratingPreview = true
        fetchQrCodePreview(this.$store).then((response: any) => {
            this.svgContent = response.content;
            setCodePreview(this.$store, response.content);
            this.isGeneratingPreview = false;
        })
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    restore() {
        const payload  = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                customizations: customizationDefaults
            }
        }
        updateProjectData(this.$store, payload);
        this.updateQr();
    }
}
