import { dynamicPageConfig } from "@/views/editor/projectData";

export type imageConfig = dynamicPageConfig & {
    title: string,
    images: string[],
    titleColor: string,
    titleFont: string,
    titleSize: string
}

export const imageDefaults: imageConfig = {
    pageColor: '#027bff',
    title: 'Select an Image',
    images: [],
    titleColor: '#fff',
    titleFont: '',
    titleSize: '24',
}