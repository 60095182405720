


















import { initServer, SERVER } from '@/utils/server';
import {Vue, Component} from 'vue-property-decorator';

@Component
export default class Suggest extends Vue {
    feature = '';

    submitFeature() {
        if(this.feature.length == 0) {
            this.$toast.error('Enter feature');
            return;
        }

        const payload = {
            feature: this.feature
        }

        initServer(SERVER);
        SERVER.post('/suggest', payload).then(() => {
            this.$toast.success('Feature submitted successfully');
        })
    }
}
