import { dynamicPageConfig } from "@/views/editor/projectData";

export type feedbackConfig = dynamicPageConfig & {
    title: string,
    logo: string,
    titleColor: string,
    titleFont: string,
    titleSize: string
}

export const feedbackDefaults: feedbackConfig = {
    title: '',
    pageColor: '#027bff',
    logo: '',
    titleColor: '#fff',
    titleFont: '',
    titleSize: '24',
}