import { dynamicPageConfig } from "@/views/editor/projectData";

export type pdfConfig = dynamicPageConfig & {
    title: string,
    titleColor: string,
    titleFont: string,
    titleSize: string
    documents: string[]
}

export const pdfDefaults: pdfConfig = {
    pageColor: '#027bff',
    titleColor: '#fff',
    titleFont: '',
    titleSize: '24',
    title: 'Select Documents',
    documents: []
}