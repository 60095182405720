



















































































import { Component, Vue } from 'vue-property-decorator';
import ColorPicker from '@/components/colorPicker.vue';
import ImageUploader from '@/components/imageUploader.vue';
import { getProjectData, updateProjectData } from '@/store/editorState';
import { generateId } from '@/utils/utils';

@Component({ components: { ColorPicker, ImageUploader } })
export default class AppQrSettings extends Vue {
    get uploaderKey() {
      return generateId();
    }
    
    get projectData() {
        return getProjectData(this.$store);
    }

    get appQrContent() {
        return this.projectData.projectData.content;
    }

        get titleFont(): string {
        return this.appQrContent.titleFont
    }

     set titleFont(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.appQrContent,
                    titleFont: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleColor(): string {
        return this.appQrContent.titleColor;
    }

    set titleColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.appQrContent,
                    titleColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get titleSize(): string {
        return this.appQrContent.titleSize;
    }

    set titleSize(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.appQrContent,
                    titleSize: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get pageColor(): string {
        return this.appQrContent.pageColor;
    }

    set pageColor(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.appQrContent,
                    pageColor: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get appName(): string {
        return this.appQrContent.name;
    }

    set appName(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.appQrContent,
                    name: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get appDescription(): string {
        return this.appQrContent.description;
    }

    set appDescription(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.appQrContent,
                    description: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get appSite(): string {
        return this.appQrContent.website;
    }

    set appSite(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.appQrContent,
                    website: value
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get androidLink(): string {
        return this.appQrContent.appLinks.android;
    }

    set androidLink(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.appQrContent,
                    appLinks: {
                        ...this.appQrContent.appLinks,
                        android: value
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);
    }

    get iosLink(): string {
        return this.appQrContent.appLinks.ios;
    }

    set iosLink(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.appQrContent,
                    appLinks: {
                        ...this.appQrContent.appLinks,
                        ios: value
                    }
                }
            }
        }

        updateProjectData(this.$store, payload);

    }

    get appLogo(): string {
        return this.appQrContent.logo;
    }

    set appLogo(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.appQrContent,
                    logo: value
                }
            }
        }

        updateProjectData(this.$store, payload);

    }
    

    proceed() {
        console.log('object');
        this.$emit('done');
    }
}
