











import { Vue, Component } from 'vue-property-decorator';
import { getProjectData, updateStepIndex } from '@/store/editorState';
import Website from './website.vue';
import Google from './google.vue';
import Email from './email.vue';
import TextContent from './text.vue';
import Call from './call.vue';
import Sms from './sms.vue';
import Whatsapp from './whatsapp.vue';
import WiFi from './wifi.vue';
import Coupon from './coupon/coupon.vue'
import Paypal from './paypal.vue';
import Bitcoin from './bitcoin.vue';
import Wedding from './wedding/wedding.vue';
import AppQr from './appQr/appQr.vue';
import Profile from './profile/profile.vue'
import SocialMedia from './social-media/social-media.vue';
import AudioQr from './audioQr/audioQr.vue'
import Feedback from './feedback/feedback.vue';
import EventQr from './event/event.vue';
import ImageQr from './image/image.vue';
import VideosQr from './videos/video.vue';
import PdfQr from './pdf/pdf.vue';
import WarningModal from './warningModal.vue';
import MenuQr from './menu/menu.vue';
import { Modal } from 'bootstrap';

@Component({ components: { Coupon, ImageQr, VideosQr, MenuQr, PdfQr, AudioQr, EventQr, WarningModal, Feedback, Profile, SocialMedia, Website, Email, AppQr, Bitcoin, Wedding, Google, WiFi, Sms, Call, Paypal, TextContent, Whatsapp } })
export default class EnterInformation extends Vue {
    get projectData() {
        return getProjectData(this.$store)
    }

    get component() {
        return this.projectData.category;
    }

    goBack() {
        new Modal(document.getElementById('warningModal')!).show();
    }
}
