






















import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Chrome } from "vue-color";
import $ from "jquery";

@Component({ components: { Chrome } })
export default class ColorPicker extends Vue {
    @Prop({ default: '' }) title!: string;
    @Prop({ default: '#000' }) value!: string;
    mounted() {
      this.color = this.value;
    }
    @Watch('value')
    onColorChange(value: string) {
      this.color = value;
    }
    color = '';
    colorType() {
      this.$emit('input', this.color)
      this.$emit('colorInput', this.color);
    }
    colorInput(color: any) {
        this.color = color.hex8;

        this.$emit('input', color.hex8)
        this.$emit('colorInput', color.hex8);
    }
    done() {
        $("body").trigger("click");
    }
}
