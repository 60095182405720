





























import { getProjectData, updateProjectData } from '@/store/editorState';
import { daddyEventBus, daddyEvents } from '@/utils/eventBus';
import {Vue, Component} from 'vue-property-decorator';
import { getParsedCountryCode } from './utils';
import CountryCodeSelector from '@/components/countryCode.vue';

@Component({ components: { CountryCodeSelector } })
export default class Whatsapp extends Vue {
    get codes() {
        return getParsedCountryCode();
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get countryCode() {
        return this.projectData.projectData.content.wapp_countrycode;
    }

    set countryCode(value: string) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    wapp_countrycode: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get telephone() {
        return this.projectData.projectData.content.wapp_number;
    }

    set telephone(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    wapp_number: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    get message() {
        return this.projectData.projectData.content.wapp_message;
    }

    set message(value) {
        const payload = {
            ...this.projectData,
            projectData: {
                ...this.projectData.projectData,
                content: {
                    ...this.projectData.projectData.content,
                    wapp_message: value
                }
            }
        }

        updateProjectData(this.$store, payload);
        daddyEventBus.$emit(daddyEvents.CustomizationEvent);
    }

    proceed() {
        console.log('object');
        this.$emit('done');
    }
}
