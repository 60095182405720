


















import { getProjectData } from '@/store/editorState';
import { Vue, Component } from 'vue-property-decorator';
import getVideoId from'get-video-id';

@Component
export default class Preview extends Vue {
     get projectData() {
        return getProjectData(this.$store);
    }

    get videoContent() {
        return this.projectData.projectData.content;
    }

    get pageColor() {
        return {
            backgroundColor: this.videoContent.pageColor
        }
    }

    get title() {
        return this.videoContent.title;
    }

    get description() {
        return this.videoContent.description
    }

    get videoSource() {
        return this.videoContent.videoSource
    }

    get videoLink() {
        return this.videoContent.videoLink
    }

    getVideoSrc() {
      if(this.videoLink.length > 0) {
            const videoDetails = getVideoId(this.videoLink);
            if(videoDetails.service == 'youtube') return `https://www.youtube.com/embed/${videoDetails.id}`;
            if(videoDetails.service == 'dailymotion') return `https://www.dailymotion.com/embed/video/${videoDetails.id}`;
            if(videoDetails.service == 'vimeo') return `https://player.vimeo.com/video/${videoDetails.id}`
            return '';
      }

      return '';
    }

     get textStyle() {
        return {
            fontFamily: this.videoContent.titleFont,
            fontSize: `${this.videoContent.titleSize}px`,
            color: `${this.videoContent.titleColor} !important`
        }
    }
}
