
























import { getProjectData } from '@/store/editorState';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Preview extends Vue {
     get projectData() {
        return getProjectData(this.$store);
    }

    get audioQrContent() {
        return this.projectData.projectData.content;
    }

    get pageColor() {
        return {
            backgroundColor: this.audioQrContent.pageColor
        }
    }

    get audioFiles() {
        return this.audioQrContent.audioFiles
    }

    get title() {
        return this.audioQrContent.title;
    }

     get textStyle() {
        return {
            fontFamily: this.audioQrContent.titleFont,
            fontSize: `${this.audioQrContent.titleSize}px`,
            color: `${this.audioQrContent.titleColor} !important`
        }
    }
}
