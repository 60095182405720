



















import {Vue, Component} from 'vue-property-decorator';
import Sidebar from './sidebar.vue';
import { getCurrentTool } from '@/store/customizeState';
import Colors from './components/colors.vue';
import Shape from './components/shape.vue';
import Settings from './components/settings.vue';
import QrPreview from './qr-preview.vue';
import Logo from './components/logo.vue'
import Frames from './components/frames.vue';
import { updateStepIndex } from '@/store/editorState';

@Component({ components: { Sidebar, Colors, Logo, Shape, QrPreview, Settings, Frames } })
export default class Customize extends Vue {
    get currentTool() {
        return getCurrentTool(this.$store);
    }

     goBack() {
        updateStepIndex(this.$store, 1);
    }
}
