import { RouteConfig } from 'vue-router';
import Dashboard from '@/views/dashboard/dashboard.vue';
import Editor from '@/views/editor/editor.vue';
import Project from '@/views/projects/project.vue';
import Finder from '@/views/finder/finder.vue';
import Settings from '@/views/settings/settings.vue';
import Folder from '@/views/folder/folder.vue';
import FolderContent from '@/views/folder/folderContent.vue';
import Whitelabel from '@/views/whitelabel/whitelabel.vue';
import WhitelabelUsers from '@/views/whitelabel/whitelabelUsers.vue';
import Knowledge from '@/views/knowledge/knowledge.vue';
import Bonus from '@/views/bonus/bonus.vue';
import Agency from '@/views/agency/agency.vue';
import Suggest from '@/views/suggest/suggest.vue'

const dashboardRoutes: Array<RouteConfig> = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    },

    {
        path: '/create',
        name: 'Create',
        component: Editor,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    },

    {
        path: '/bonus',
        name: 'Bonus',
        component: Bonus,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    },

    {
        path: '/knowledge',
        name: 'Knowledge',
        component: Knowledge,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    },

    {
        path: '/project',
        name: 'Project',
        component: Project,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    },

    {
        path: '/folder',
        name: 'Folder',
        component: Folder,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        },
    },

    {
        path: '/folder-content/:id',
        component: FolderContent,
        name: 'Folder Content',
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        },
    },

    {
        path: '/whitelabel',
        component: Whitelabel,
        name: 'Whitelabel',
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        },
    },

    {
        path: '/agency',
        component: Agency,
        name: 'Agency',
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        },
    },

    {
        path: '/whitelabel-users',
        component: WhitelabelUsers,
        name: 'WhitelabelUsers',
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        },
    },

    {
        path: '/finder',
        name: 'Finder',
        component: Finder,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    },

    {
        path: '/suggest',
        name: 'Suggest',
        component: Suggest,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    },

    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            layout: 'dashboard-layout',
            requiresAuth: true
        }
    }
];

export default dashboardRoutes;