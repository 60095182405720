






























































import { getQrCodePreview, createProject, getProjectData, generateImages, fetchQrCodePreview, setCodePreview } from '@/store/editorState';
import { Vue, Component } from 'vue-property-decorator';
import Loader from '@/components/loader.vue';
import axios from 'axios';
import download from 'downloadjs'
import Copy from 'copy-to-clipboard';

@Component({ components: { Loader } })
export default class ScanPreview extends Vue {
    isCreatingProject = false;

    get svgContent() {
        return getQrCodePreview(this.$store)
    }

    get projectData() {
        return getProjectData(this.$store);
    }

    get qrCodeUrl() {
        return this.projectData.qrCodeUrl;
    }

    get codeAvailable() {
        let codeExist = true;
        Object.keys(this.qrCodeUrl).forEach(key => {
            codeExist = codeExist && this.qrCodeUrl[key].length > 0;
        })

        return codeExist;
    }

    copyLink() {
        Copy(`https://code.qrverse.io/${this.projectData.id}`)
        this.$toast.success('Link Copied');
    }

    createProject() {
        if (!this.validate()) {
            return;
        }
        this.isCreatingProject = true;
        fetchQrCodePreview(this.$store).then((response: any) => {
            setCodePreview(this.$store, response.content);
            createProject(this.$store)
                .then((response: any) => {
                    generateImages(this.$store, { svgString: this.svgContent, projectId: this.projectData.id }).then(() => {
                        this.$toast.success(response)
                        this.isCreatingProject = false;
                    })
                }).catch(err => {
                    this.$toast.error(err);
                    this.isCreatingProject = false;
                })
        })
    }

    validate() {
        if (!this.projectData.folderId) {
            this.$toast.error('Select a folder');
            return false;
        }

        return true;
    }

    download(type: string) {
        const url = this.qrCodeUrl[type]
        const request = axios.get(url, { responseType: 'arraybuffer' })
        request.then((response) => {
            download(response.data, this.projectData.title, `image/${type == 'svg' ? 'svg+xml' : type}`);
            // resolve(response.data);
        }).catch((error) => {
            // return reject(error)
        });
    }

    shareFacebook() {
        const url = `https://www.facebook.com/sharer/sharer.php?u=https://code.qrverse.io/${this.projectData.id}`;
        window.open(url, '_blank')
    }
}
