











import {Vue, Component} from 'vue-property-decorator';
import PdfSettings from './settings.vue';
import Preview from './preview.vue';

@Component({ components: { PdfSettings, Preview } })
export default class PdfQr extends Vue {
    done() {
        this.$emit('done');
    }
}
