import { ApplicationState } from "./applicationState";
import { ActionContext } from "vuex";
import { getStoreAccessors } from "vuex-typescript";
import { initServer, SERVER } from "@/utils/server";
type CustomizeContext = ActionContext<CustomizeState, ApplicationState>

export interface CustomizeState {
    currentTool: string
}

const initialState: CustomizeState = {
    currentTool: 'colors'
}

export const customizeState = {	
     namespaced: true, 
     state: initialState,
     getters: {
        getCurrentTool(state: CustomizeState) {
            return state.currentTool;
        }
     },
     mutations: {
         setCurrentTool(state: CustomizeState, tool: string){
             state.currentTool = tool
         },

         resetTool(state: CustomizeState) {
             state.currentTool = 'colors';
         }
     },
     actions: {
         setCurrentTool(context: CustomizeContext, tool: string) {
             context.commit('setCurrentTool', tool);
         },

         resetEditor(context: CustomizeContext) {
             context.commit('resetTool');
         }
     },
}

const { read, dispatch } = getStoreAccessors<CustomizeState, ApplicationState>('customize');

const getters = customizeState.getters;
const actions = customizeState.actions;

export const getCurrentTool = read(getters.getCurrentTool);

export const setCurrentTool = dispatch(actions.setCurrentTool);
export const resetTool = dispatch(actions.resetEditor);