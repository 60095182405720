
































import {Vue, Component, Prop} from 'vue-property-decorator';
import { Step, StepState } from '@/@types/steps';

@Component
export default class Stepper extends Vue {
    @Prop({ default: () => { return [] }, required: true }) steps!: Step[];
    @Prop({ default: 0 }) activeStepIndex!: number;
    @Prop({ default: 40 }) dimension!: number;
    @Prop({ default: true }) showNumber!: boolean;

    get circleDimensions(): {[cssProperty: string]: string} {
        const size = `${this.dimension}px`;

        return {
            width: size,
            height: size
        }
    }

    get iconSize(): number {
        return this.dimension * 0.05
    }

    getState(stepIndex: number) {
        if (stepIndex > this.activeStepIndex) return StepState.STALE;

        if (stepIndex < this.activeStepIndex) return StepState.FINISHED;

        if ((stepIndex === this.activeStepIndex) && this.activeStepIndex !== this.steps.length -1) return StepState.ACTIVE;

        if ((stepIndex == this.activeStepIndex) && this.activeStepIndex === this.steps.length - 1) return StepState.DONE;
    }
}
