






import { Vue, Component } from "vue-property-decorator";

@Component
export default class AppLayout extends Vue {
    defaultLayout = 'app-default-layout'

    get layout(){
      const layout = this.$route.meta!.layout || this.defaultLayout
      return () => import(`./${layout}.vue`)
    }
}

